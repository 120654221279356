import React, { useState, useEffect } from 'react';
import Konva from 'konva';
import Error from '../components/error.js';
import { Text, Shape, Tag, Label } from 'react-konva';
import { rowStyles } from '../utils/row_status_colors';
import { bin_locations_by_location } from '../api/bin_location';
import { bin_location_marker_update, bin_location_marker_delete } from '../api/floorplan';
import { errorMessage } from '../utils/error_message';
import { useTranslation } from 'react-i18next';

export default function Markers(props) {
  const [hasError, setErrors] = useState(false);
  const [bins, setBins] = useState([]);
  const { t } = useTranslation();
  const [shouldShowText, setShouldShowText] = useState(false);
  const [label, setLabel] = useState({});
  let role_admin = sessionStorage.getItem('role') === 'admin';

  async function fetchData() {
    const bin = await bin_locations_by_location(props.param)
    bin
    .json()
    .then(res => {
      setBins(res.data)
    })
  }

  useEffect(() => {
    fetchData()
  },[])

  const handleDragStart = e => {
    e.target.setAttrs({
      shadowOffset: {
        x: 15,
        y: 15
      },
      scaleX: 1.1,
      scaleY: 1.1
    })
    setShouldShowText(false)
  }

  const handleDragEnd = (e, i) => {
    e.target.to({
      duration: 0.5,
      easing: Konva.Easings.ElasticEaseOut,
      scaleX: 1,
      scaleY: 1,
      shadowOffsetX: 5,
      shadowOffsetY: 5
    })
    const bin = {
      marker_id: i.id,
      id: e.target.attrs.plan_id,
      x: e.target.attrs.x,
      y: e.target.attrs.y,
    }
    bin_location_marker_update(bin).then((res)=>{
      if (res.error) return errorMessage(res, setErrors)
      props.markers[props.markers.indexOf(i)] = res.data;
    })
  }

  const handleOnMouseOver = (e, i) => {
    setShouldShowText(false)
    if (!shouldShowText) {
      let direction = 'down'
      const width = e.target.attrs.width - 200
      direction = (e.target.attrs.x > 200 && e.target.attrs.y > 133) ? 'down' : 'up'
      direction = (e.target.attrs.x > width) ? 'right' : direction
      direction = (e.target.attrs.x < 200) ? 'left' : direction
      let bin = bins && bins.find(b => b.id === i.bin_location_id)
      var status_label = "connection_status_"
      if (bin && bin.sensor){
        if (bin.sensor.connection_status === 'error')
          status_label = "connection_status_error"
        if (bin.sensor.connection_status === 'ok')
          status_label = "connection_status_ok"
      }
      setLabel({direction:direction, x:e.target.attrs.x, y:e.target.attrs.y, text: ` ${t("bin_location")}: ${(bin && bin.name) || ''}
 ${t("sensor")}:  ${(bin && bin.sensor && bin.sensor.sensor_id) || ''}
 ${t("waste_stream")}:  ${(bin && bin.waste_stream && bin.waste_stream.name) || ''}
 ${t("bin_type")}:  ${(bin && bin.bin_type && bin.bin_type.name) || ''}
 ${t("battery_level")}:  ${(bin && bin.battery_level) || ''}
 ${t("volume_level")}:  ${(bin && bin.volume_level)  || ''}
 ${t("status")}:  ${t(status_label)}`
      })
      setShouldShowText(true)
    }
  }

  const handleOnMouseOut = () => {
    setShouldShowText(false)
  }

  const handleOndblClick = (e, fid, mid) => {
    e.target.destroy()
    new Promise((resolve, reject) => {
      let oldData = { id: fid, marker_id: mid }
      bin_location_marker_delete(oldData).then(res => {
        if (res.error) { errorMessage(res, setErrors); reject(); return }
        let marker = props.markers.find(x => x.id === mid)
        props.markers.splice(props.markers.indexOf(marker), 1);
        // when you remove a marker/bin_location add it to the dropdown
        props.binlocations.splice(0, 0, bins.find(x => x.id === marker.bin_location_id));
      })
      resolve()
    })
  }

return (<>
  { hasError && <Error hasError={ hasError } setErrors={ setErrors } />}
  { props.markers && props.markers.map((i, index)=> (<>
  <Shape
  sceneFunc={(ctx, shape) => {
    ctx.beginPath();
    ctx.arc(0, 0, 7, 0, 2 * Math.PI);
    ctx.lineWidth = 5;
    ctx.strokeStyle = "black";
    ctx.stroke();
    ctx.fillStrokeShape(shape);
  }}
  key={i.id}
  id={i.id}
  plan_id={props.plan_id}
  x={i.x}
  y={i.y}
  draggable={role_admin ? !(/Mobi|Android/i.test(navigator.userAgent)): false}
  numPoints={5}
  innerRadius={20}
  outerRadius={40}
  fill={rowStyles(bins && bins.find(b => b.id === i.bin_location_id))}
  opacity={1.0}
  shadowColor={'black'}
  shadowBlur={20}
  shadowOffsetX={10}
  shadowOffsetY={10}
  shadowOpacity={0.8}
  onDragStart={handleDragStart}
  onDragEnd={(e) => handleDragEnd(e, i)}
  onMouseEnter={(e) => handleOnMouseOver(e, i)}
  onTap={(e) => handleOnMouseOver(e, i)}
  onMouseLeave={handleOnMouseOut}
  // onTouchEnd={handleOnMouseOut}
  onDblClick={(e) => handleOndblClick(e, props.plan_id, i.id)}
  // onDblTap={(e) => handleOndblClick(e, props.plan_id, i.id)}
  />
  {shouldShowText && <>
    <Label
    key={i.id}
    x={label.x}
    y={label.y}
    opacity={0.75}>
    <Tag
    fill={'black'}
    pointerDirection={label.direction}
    pointerWidth={10}
    pointerHeight={10}
    lineJoin={'round'}
    shadowColor={'black'}
    shadowBlur={10}
    shadowOffsetX={10}
    shadowOffsetY={10}
    shadowOpacity={0.1}>
    </Tag>
    <Text
    text={label.text}
    fontFamily={'Calibri'}
    fontSize={18}
    padding={5}
    fill={'white'} />
    </Label>
    </>}
    </>))}
    </>)
  }
