import React, { useState, useEffect } from "react";
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { sensors_report } from '../api/sensors';
import GroupByBar from './group_by_bar';
import GroupRow from './group_row';
import { errorMessage } from '../utils/error_message';
import Error from '../components/error.js';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../utils/date';
import { formatSensorData } from '../utils/sensor';

export default function Sensors() {
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumn] = useState([]);
  const grouppage = 'sensors.'

  function customRender(value, renderType, renderFunc, field) {
    if (renderType === 'row') {
      return value[field]
    }
    if (renderType === 'group') {

      return value
    }
  }

  function renderCellData(column) {
    sessionStorage.setItem(column, 1)
  }

  async function fetchData() {
    const res = await sensors_report()
    const json = await res.json()

    let data = json && json.data && json.data.map(i => {
      return formatSensorData(i)
    })

    setColumn([
      {
        title: t('sensor_id'),
        field: 'sensor_id',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'sensor_id') || -1,
        // render: (row, renderType) => customRender(row, renderType, renderCellData, 'sensor_id')
      },
      {
        title: t('customer'),
        field: 'customer.name',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'customer.name') || -1,
        render: (row, renderType) => {
          return typeof row === 'string' ?
            row : row && row.customer === null ? '' :
              row && row.customer && row.customer.id &&
              <Link to={"/customer/" + row.customer.id}>{row.customer.name}</Link>
        },
      },
      {
        title: t('project'),
        field: 'project.name',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'project.name') || -1,
        render: (row, renderType) => {
          // customRender(row, renderType, renderCellData, 'project.name')
          return typeof row === 'string' ? row : row && row.project === null ? '' : row && row.project && row.project.id && <Link to={"/customer/project/" + row.project.id}>{row.project.name}</Link>
        },
      },
      {
        title: t('bin_locations'), field: 'bin_location.name',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'bin_location.name') || -1,
        render: (row, renderType) => {
          // customRender(row, renderType, renderCellData, 'bin_location.name')
          return row && row.id && <Link to={'/plan/' + (row.location && row.location.id)}>{(row.bin_location && row.bin_location.name)}</Link> || row
        }
      },
      {
        title: t('level'),
        field: 'location.level',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'location.level') || -1,
        customSort: (a, b, renderType) => {
          if (renderType === 'row') {
            if (!a.location || !a.location.level) return -1;
            if (!b.location || !b.location.level) return 1;
            var x = (a.location && a.location.level) - 0
            var y = (b.location && b.location.level) - 0
            return x < y ? -1 : x > y ? 1 : 0;
          }
          if (renderType === 'group') {
            // if ( typeof(a) === 'undefined') return
            // if ( typeof(b) === 'undefined') return
            if (!a) return -1
            if (!b) return 1
            var x = a - 0
            var y = b - 0
            return x < y ? -1 : x > y ? 1 : 0;
          }
        },
      },
      {
        title: t('connection_quality'),
        field: 'connection_quality',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'connection_quality') || -1,
      },
      {
        title: t('connection'),
        field: 'connection_state',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'connection_state') || -1,
        render: (row, renderType) => {
          let connection_state = 'false'
          if (renderType === 'row') {
            connection_state = row.connection_state
          } else if (renderType === 'group') {
            connection_state = row
            return (<label style={{
              margin: 0,
              backgroundColor: connection_state === 'true' ? 'rgba(63, 195, 128, 0.9)' : 'rgba(242, 38, 19, 0.9)',
              color: 'white',
              textAlign: 'center',
              padding: '4px',
            }}>
            {(connection_state === 'true') ? t('sensor_state_connected') : t('sensor_state_disconnected')}
            </label>)
          }
          return (<div style={{
            margin: 0,
            backgroundColor: connection_state === 'true' ? 'rgba(63, 195, 128, 0.9)' : 'rgba(242, 38, 19, 0.9)',
            color: 'white',
            textAlign: 'center'
          }}>
          {(connection_state === 'true') ? t('sensor_state_connected') : t('sensor_state_disconnected')}
          </div>)
        }
      },
      {
        title: t('last_seen'),
        field: 'last_seen',
        customSort: (a, b, renderType) => {
          if (renderType === 'row') {
            if (!a || !a.last_seen) return -1;
            if (!b || !b.last_seen) return 1;
            var x = (a.last_seen).replace(/\D+/g, '');
            var y = (b.last_seen).replace(/\D+/g, '');
            return x < y ? -1 : x > y ? 1 : 0;
          }
          if (renderType === 'group') {
            if (!a) return -1;
            if (!b) return 1;
            var x = (a).replace(/\D+/g, '');
            var y = (b).replace(/\D+/g, '');
            return x < y ? -1 : x > y ? 1 : 0;
          }
        },
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'last_seen') || -1,
        render: (row, renderType) => {
          var date = customRender(row, renderType, renderCellData, 'last_seen')
          return formatDate(date)
        }
      }
    ])
    if (json.error) return errorMessage(json, setErrors)
    setData(data)
  }

  useEffect(() => {
    const abortController = new AbortController();
    fetchData()
    return function cleanup() {
      abortController.abort();
    };
  }, [i18n.lang])

  return (<>
    { hasError && <Error hasError={hasError} setErrors={() => setErrors()} />}
    <MaterialTable
      title={t('sensors')}
      onGroupRemoved={(group) => {
        sessionStorage.removeItem(grouppage + group.field)
      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} of {count}',
          labelRowsSelect: t("labelRowsSelect"),
          labelRowsPerPage: t("labelRowsPerPage"),
          firstAriaLabel: t("firstAriaLabel"),
          firstTooltip: t("firstTooltip"),
          previousAriaLabel: t("previousAriaLabel"),
          previousTooltip: t("previousTooltip"),
          nextAriaLabel: t("nextAriaLabel"),
          nextTooltip: t("nextTooltip"),
          lastAriaLabel: t("lastAriaLabel"),
          lastTooltip: t("lastTooltip"),
        },
        toolbar: {
          nRowsSelected: '{0} row(s) selected',
          searchTooltip: t("search"),
          searchPlaceholder: t("search"),
          exportTitle: t("export"),
        },
        header: {
          actions: t("action")
        },
        grouping: {
          placeholder: t('groupingPlaceholder'),
        },
        body: {
          emptyDataSourceMessage: t("no_records"),
          filterRow: {
            filterTooltip: t("filter")
          },
          editRow: {
            saveTooltip: t("save"),
            cancelTooltip: t("cancel"),
            deleteText: t("delete_text")
          },
          addTooltip: t("add"),
          deleteTooltip: t("delete"),
          editTooltip: t("edit")
        }
      }}
      columns={columns}
      data={data}
      options={{
        grouping: true,
        paging: true,
        pageSize: 10,
        pageSizeOptions: ([10, 25, 50]),
        cellStyle: ({ padding: 2 }),
        headerStyle: ({ padding: 6 }),
      }}
      components={{
        GroupRow: props => (new GroupRow(props)),
        Groupbar: props => (GroupByBar(props, grouppage))
      }}
    />
  </>)
}
