import { url, headers } from './url';

// container Locations
export async function waste_stream() {
  return await fetch(url + 'waste_stream', {
    method: 'GET',
    headers: headers()
  });
};

export async function waste_stream_add(data) {
  const res = await fetch(url + 'waste_stream', {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function waste_stream_update(data) {
  const res = await fetch(url + 'waste_stream/' + data.id, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json();
};

export async function waste_stream_del(id) {
  const res = await fetch(url + 'waste_stream/' + id, {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json()
};
