export function formatBinLocationData(data) {
  // battery_level
  if (data.battery_level == null) {
    // error: no battery level
    data.battery_level = '-1'
  } else if (data.sensor && data.sensor.connection_status == 'error') {
    // error: connection_status
    data.battery_level = '-1'
  } else if (typeof data.battery_level === 'number') {
    // ok
  } else {
    // error: invalid battery_level
    data.battery_level = '-1'
  }

  // volume level
  if (data.volume_level == null) {
    // error: no volume level
    data.volume_level = '-1'
  } else if (data.sensor && data.sensor.connection_status == 'error') {
    // error: connection_status
    data.volume_level = '-1'
  } else if (typeof data.volume_level === 'number') {
    // ok
  } else {
    // error: invalid volume_level
    data.volume_level = '-1'
  }

  data.battery_level.toString()
  data.volume_level.toString()

  return data
}
