
import "react-app-polyfill/ie11";
import React from "react";
import Hotkeys from 'react-hot-keys';
import { ConfirmProvider } from "material-ui-confirm";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {
  Router,
  Route,
  Switch,
} from "react-router-dom";
import './index.css';
import './i18n';
import template from "./layouts/template.js";

const hist = createBrowserHistory();

const onKeyDown = function (keyName, e, handle) {
  if (keyName === "ctrl+q" && document.querySelectorAll("[title*='ctrl+q']")[0]) {
    document.querySelectorAll("[title*='ctrl+q']")[0].click()
  }
  if (keyName === "ctrl+shift+s" && document.querySelectorAll('[mode]')[0]) {
    document.querySelectorAll('[mode]')[0].getElementsByTagName('button')[0].click()
  }
  if (keyName === "ctrl+x" && document.querySelectorAll('[mode]')[0]) {
    document.querySelectorAll('[mode]')[0].getElementsByTagName('button')[1].click()
  }
  if (keyName === "ctrl+z" && document.getElementsByTagName('tr')[1]) {
    document.getElementsByTagName('tr')[1].getElementsByTagName('button')[1].click()
  }
}

ReactDOM.render(
  <Hotkeys
        keyName="ctrl+shift+s,ctrl+x,ctrl+q,ctrl+z"
        onKeyDown={onKeyDown.bind(this)}
        filter={event => true }
      >
    <Router history={hist}>
      <Switch>
        <ConfirmProvider>
          <Route path="/" component={template} />
        </ConfirmProvider>
      </Switch>
    </Router>
    </Hotkeys>
    ,
  document.getElementById("root")
);
