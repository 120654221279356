import { current_user } from './user.js';
import { logout } from "../utils/logout";
import i18n from '../i18n';

export function remember() {
  const token = sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
  if (token) {
    sessionStorage.setItem('access_token', token)
    sessionStorage.setItem('role', localStorage.getItem('role'))
    return current_user().then((res) => {
      if (res && res.data) {
        sessionStorage.setItem('role', res.data.roles[1] || res.data.roles[0])
        sessionStorage.setItem('user', res.data.email)
        localStorage.setItem('name', res.data.name)
        localStorage.setItem('language', res.data.language_code)
        i18n.changeLanguage(res.data.language_code)
        return true
      }
      if (res.success === false || res === false) logout()
      return false
    }).catch(e => {
      return false
    })
  }
}
