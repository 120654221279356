import { url, headers } from './url';

export async function location_floorplan(id) {
  return await fetch(`${url}location/${id}/floorplan`, {
    method: 'GET',
    headers: headers()
  });
}

export async function floorplan(data) {
  const res = await fetch(url + 'floorplan', {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    },
    body: data
  });
  return res.json()
};

export async function floorplan_update(data, id) {
  const res = await fetch(`${url}floorplan/${id}`, {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('access_token')
    },
    body: data
  });
  return res.json()
};

export async function floorplan_delete(id) {
  const res = await fetch(`${url}floorplan/${id}`, {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json()
};

export async function bin_location_marker_delete(data) {
  const res = await fetch(`${url}floorplan/${data.id}/bin_location_marker/${data.marker_id}`, {
    method: 'DELETE',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.ok || res.json()
};

//PUT marker
export async function bin_location_marker_update(data) {
  const res = await fetch(`${url}floorplan/${data.id}/bin_location_marker/${data.marker_id}`, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

// POST marker
export async function bin_location_marker_create(data) {
  const res = await fetch(`${url}floorplan/${data.id}/bin_location_marker`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};
