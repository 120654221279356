export function en() {
  return {
    "The type must be a string.": "Filter field type required",
    "Location with this name and level already exists.": "A location with this name and level already exists",
    "date": "Date",
    "resolved": "Resolved",
    "edit_profile": "Edit Profile",
    "complete_profile": "Complete your profile",
    "username": "Username",
    "role": "Role",
    "update_profile": "Update Profile",
    "en": "English",
    "nl": "Dutch",
    "fr": "French",
    "error": "Error",
    "warning": "Warning",
    "info": "Information",
    "log": "Log",
    "all": " - ",
    "unresolved": "Unresolved",
    "bin_volume": "Bin volume",
    "battery_level": "Battery level",
    "connection_status_ok": "online",
    "connection_status_error": "offline",
    "connection_status_": "no sensor",
    "sensor_status": "Sensor status",
    "sensor_offline": "Sensor offline",
    "sensor_online": "Sensor online",
    "sensor_removed": "Sensor removed",
    "sensor_added": "Sensor added",
    "replace_battery": "Replace battery",
    "empty_bin_timed": "Empty the bin, time has passed",
    "empty_bin_full": "Empty the bin, bin is full",
    "check_sensor": "Check sensor",
    "sensor_communication": "Sensor communication",
    "customer": "Customer",
    "customers": "Customers",
    "country": "Country",
    "language": "Language",
    "project": "Project",
    "projects": "Projects",
    "adres": "Address",
    "postal_code": "Postal code",
    "city": "City",
    "favorite": "Favorite",
    "favorites": "Favorite",
    "sign_out": "Sign out",
    "settings": "Settings",
    "mutation_logs": "Mutation logs",
    "access_control": "Access control",
    "reports": "Reports",
    "notification": "Notification",
    "work_in_progress": "Work in progress",
    "container": "Container",
    "containers": "Containers",
    "location": "Location",
    "locations": "Locations",
    "bin_locations": "Container locations",
    "bin_location": "Container location",
    "resource": "Resource",
    "created": "Created",
    "deleted": "Deleted",
    "updated": "Updated",
    "data": "Data",
    "home": "Home",
    "type": "Type",
    "container_locaties_project": "Container location project",
    "sensor": "Sensor",
    "sensors": "Sensors",
    "protocol_type": "Protocol type",
    "calibration_settings_int": "Calibration settings int",
    "calibration_settings_float": "Calibration settings float",
    "calibration_settings_string": "Calibration settings string",
    "interval_settings_int": "interval_settings_int",
    "interval_settings_float": "interval_settings_float",
    "interval_settings_string": "interval_settings_string",
    "interval_settings_data_interval": "Data interval",
    "level": "Floor",
    "bin_type": "Container type",
    "bin_types": "Container types",
    "waste_stream": "Waste stream",
    "volume_level": "Volume level",
    "save": "Save (ctrl+shift+s)",
    "save_settings": "Save",
    "cancel_dialog": "Cancel",
    "cancel": "Cancel (ctrl+x)",
    "attention": "Attention!",
    "are_you_sure": "Yes, I'm sure",
    "delete_sensor_1": "Are you sure want to disconnect sensor",
    "delete_sensor_2": "?",
    "delete_plan": "Are you sure you want to delete this floorplan?",
    "delete_text": "Are you sure you want to delete this row?",
    "delete_marker": "Are you sure you want to delete this marker?",
    "add": "Add (ctrl+q)",
    "delete": "Delete",
    "edit": "Edit",
    "no_records": "No records to display",
    "action": "Actions",
    "mutation_action": "Actions",
    "search": "Search",
    "export": "Export",
    "labelRowsSelect": "rows",
    "labelRowsPerPage": "Rows per page:",
    "firstAriaLabel": "First Page",
    "firstTooltip": "First Page",
    "previousAriaLabel": "Previous Page",
    "previousTooltip": "Previous Page",
    "nextAriaLabel": "Next Page",
    "nextTooltip": "Next Page",
    "lastAriaLabel": "Last Page",
    "lastTooltip": "Last Page",
    "groupingPlaceholder": "Drag headers here to group by",
    "sensor_id": "Sensor",
    "sensor_type": "Sensor type",
    "protocol": "Protocol",
    "status": "Status",
    "connection": "Connection",
    "connection_quality": "Connection quality",
    "sensor_state_connected": "Connected",
    "sensor_state_disconnected": "Disconnected",
    "connection_error": "Connection error, API server not available",
    "last_seen": "Last seen",
    "last_downlink": "Last downlink sent",
    "location_level": "Location on floor",
    "from": "Period from",
    "to": "Period to",
    "sensor_location": "Sensor Location",
    "unlink": "Unlink sensor",
    "no_sensors": "No sensors",
    "no_sensor": "No sensor",
    "link_sensor": "Link sensor",
    "email": "Email Address",
    "password": "Password",
    "remember": "Remember me",
    "login": "Sign in",
    "sign_in": "Sign in",
    "floorplan": "Floorplan",
    "The given data was invalid.": "The given data was invalid.",
    "default": "Default",
    "custom": "Custom",
    "user": "User",
    "users": "Users",
    "admin": "Administrator",
    "notification_field": "Notification",
    "urgency": "Urgency level",
    "creation_date": "Creation date",
    "battery_error": "Battery error",
    "battery_warning": "Battery warning",
    "time_error": "Time error",
    "time_warning": "Time warning",
    "volume_error": "Volume error",
    "volume_warning": "Volume warning",
    "The roles field is required.": "Het rol veld is verplicht.",
    "The email field is required.": "Het email veld is verplicht.",
    "The password field is required.": "Het wachtwoord veld is verplicht.",

    "hour": "hour",
    "minutes": "minutes",

    "bin_location_setting": "Container location setting",
    "default_bin_location_setting": "Global container location setting",

    "global_bin_location_settings": "Container location settings",
    "change_global_bin_location_settings": "Configure global settings for container locations",

    "waste_streams": "Waste streams",

    "max_volume_cm": "Measured volume when empty (cm)",
    "min_volume_cm": "Measured volume when full (cm)",

    "floorplan_bin_marker": "Floorplan & Markers",
    "bin_location_sensor": "Sensors",
    "user-roles": "User roles"
  }
}
