import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableEditRow, MTableToolbar } from 'material-table';
import GroupByBar from './group_by_bar';
import GroupRow from './group_row';
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from '@material-ui/icons/Refresh';
import Avatar from '@material-ui/core/Avatar';
import Error from '../components/error.js';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import { location_add, location_update, location_del, project_locations } from '../api/location';
import { bin_type, waste_type } from '../api/miscellaneous';
import { project } from '../api/project';
import { project_bin_locations, bin_location_add, bin_location_update, bin_location_del } from '../api/bin_location';
import { batteryStyles, levelStyles, rowStyles } from '../utils/row_status_colors';
import { errorMessage } from '../utils/error_message';
import { Link } from 'react-router-dom';
import CustomizedDialogs from '../components/dialog.js';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { tabindex } from '../utils/tabindex';
import { formatBinLocationData } from '../utils/bin_location';

export default function Project() {
  const grouppage = 'project_locations.'
  const { t, i18n } = useTranslation();
  const role_admin = sessionStorage.getItem('role') === 'admin';
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [proj, setProj] = useState([]);
  const [proj_locs, setLocs] = useState([]);
  const [look_locs, setLookLocs] = useState({});
  const [columns, setColumn] = useState([]);
  let { id } = useParams();

  async function fetchData() {
    const proj = await project(id)
    proj
    .json()
    .then(res => {
      setProj(res.data)
    })

    const waste_sort = {}
    const resp = await waste_type()
    resp
    .json()
    .then(res => {
      res.data && res.data.map(i => {
        const { id, name } = i;
        return waste_sort[ id ] = name
      })
    })

    const cont_sort = {}
    const r = await bin_type()
    r
    .json()
    .then(res => {
      res.data && res.data.map(i => {
        const { id, name } = i;
        return cont_sort[ id ] = name
      })
    })

    const res = await project_bin_locations(id)
    res
    .json()
    .then(res => {
      let data = res && res.data && res.data.map(i => {
        return formatBinLocationData(i)
      })

      setData(data)
      setColumn([
        {
          title: `${t('sensor')} ${t('location')}`,
          field: 'name',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'name') || -1,
          render: (row) => { return typeof row === 'string' ? row : <CustomizedDialogs update={fetchData} row={row} />},
          editComponent: props => (<div className="MuiFormControl-root MuiTextField-root">
            <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
              <input
                className="MuiInputBase-input MuiInput-input"
                type="text"
                value={props.value || ""}
                onChange={e => props.onChange(e.target.value)}
                autoFocus={true}
              />
            </div>
          </div>)
        },
        {
          title: t('location'),
          field: 'location.id',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'location.id') || -1,
          render: (row) => { return typeof row === 'string' ? row : <Link to={"/plan/" + row.location.id}>{row.location.name}</Link> },
          lookup: look_locs,
        },
        {
          title: t('level'),
          field: 'location.level',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'location.level') || -1,
          editComponent: () => <></>,
          customSort: (a, b, renderType) => {
            if (renderType === 'row') {
              if (!a.location || !a.location.level) return -1;
              if (!b.location || !b.location.level) return 1;
              var x = (a.location && a.location.level) - 0
              var y = (b.location && b.location.level) - 0
              return x < y ? -1 : x > y ? 1 : 0;
            }
            if (renderType === 'group') {
              if (typeof (a) === 'undefined') return;
              if (typeof (b) === 'undefined') return;
              if (!a) return -1;
              if (!b) return 1;
              var x = a - 0
              var y = b - 0
              return x < y ? -1 : x > y ? 1 : 0;
            }
          },
        },
        {
          title: t('bin_type'),
          field: 'bin_type.id',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'bin_type.id') || -1,
          lookup: cont_sort,
        },
        {
          title: t('waste_stream'),
          field: 'waste_stream.id',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'waste_stream.id') || -1,
          lookup: waste_sort,
        },
        {
          title: t('battery_level'),
          field: 'battery_level',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'battery_level') || -1,
          editComponent: () => <></>,
          render: (row, renderType) => {
            var battery_level = '-1'
            if (renderType === 'row') {
              battery_level = row.battery_level
              return <Link to={'/sensors'}>{battery_level >= '0' ? <Avatar style={{ margin: 0, backgroundColor: batteryStyles(row), color: 'white', textAlign: 'center', fontSize: 'small' }}>{battery_level}%</Avatar> : <BatteryUnknownIcon style={{ color: 'white', backgroundColor: 'rgba(243, 59, 43, 1)' }} />}</Link> 
            } else if (renderType === 'group') {
              battery_level = row
              // if not error, just return the value
              return battery_level >= '0' ? battery_level : <BatteryUnknownIcon style={{ color: 'gray'}} />
            } 
          },
        },
        {
          title: t('volume_level'),
          field: 'volume_level',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'volume_level') || -1,
          editComponent: () => <></>,
          render: (row, renderType) => { 
            var volume_level = '-1'
            if (renderType === 'row') {
              volume_level = row.volume_level
              return <Link to={'/sensors'}>{volume_level >= '0' ? <Avatar style={{ margin: 0, backgroundColor: levelStyles(row), color: 'white', textAlign: 'center', fontSize: 'small' }}>{volume_level}%</Avatar> : <SignalCellularConnectedNoInternet0BarIcon style={{ color: 'white', backgroundColor: 'rgba(243, 59, 43, 1)' }} />}</Link> 
            } else if (renderType === 'group') {
              volume_level = row
              // if not error, just return the value
              return  volume_level >= '0' ? volume_level : <SignalCellularConnectedNoInternet0BarIcon style={{ color: 'gray' }} />

            }
          },
        },
      ])
      tabindex()
    })

    const proj_loc = await project_locations(id)
    proj_loc
    .json()
    .then(res => {
      res.data && res.data.map(i => {
        const { id, name, level } = i;
        return look_locs[ id ] = `${name} - ${level || ''}`
      })
      setLocs(res.data)
      setLookLocs({})
      setLookLocs(look_locs)
    })
  }

  useEffect(() => {
    fetchData()
  }, [i18n.lang])

  const localization = {
    pagination: {
      labelDisplayedRows: '{from}-{to} of {count}',
      labelRowsSelect: t("labelRowsSelect"),
      labelRowsPerPage: t("labelRowsPerPage"),
      firstAriaLabel: t("firstAriaLabel"),
      firstTooltip: t("firstTooltip"),
      previousAriaLabel: t("previousAriaLabel"),
      previousTooltip: t("previousTooltip"),
      nextAriaLabel: t("nextAriaLabel"),
      nextTooltip: t("nextTooltip"),
      lastAriaLabel: t("lastAriaLabel"),
      lastTooltip: t("lastTooltip"),
    },
    grouping: {
      placeholder: t('groupingPlaceholder'),
    },
    toolbar: {
      nRowsSelected: '{0} row(s) selected',
      searchTooltip: t("search"),
      searchPlaceholder: t("search"),
      exportTitle: t("export"),
    },
    header: {
      actions: t("action")
    },
    body: {
      emptyDataSourceMessage: t("no_records"),
      filterRow: {
        filterTooltip: t("filter")
      },
      editRow: {
        saveTooltip: t("save"),
        cancelTooltip: t("cancel"),
        deleteText: t("delete_text")
      },
      addTooltip: t("add"),
      deleteTooltip: t("delete"),
      editTooltip: t("edit")
    }
  }

  const editable = {
    onRowAdd: (newData) =>
    new Promise((resolve, reject) => {
      newData.location_id = newData.location && newData.location.id
      newData.bin_type_id = newData.bin_type && newData.bin_type.id
      newData.waste_stream_id = newData.waste_stream && newData.waste_stream.id
      bin_location_add(newData).then(res => {
        if (res.error) { errorMessage(res, setErrors); reject(); return }
        setData((prevState) => {
          const data = [...prevState]
          data.splice(0, 0, formatBinLocationData(res.data));
          return data
        })
        setErrors()
        resolve()
      })
    }),
    onRowUpdate: (newData, oldData) =>
    new Promise((resolve, reject) => {
      if (oldData) {
        newData.location_id = newData.location && newData.location.id
        newData.bin_type_id = newData.bin_type && newData.bin_type.id
        newData.waste_stream_id = newData.waste_stream && newData.waste_stream.id
        bin_location_update(newData).then(res => {
          if (res.error) { errorMessage(res, setErrors); reject(); return }
          setData((prevState) => {
            const data = [...prevState]
            data[data.indexOf(oldData)] = formatBinLocationData(res.data)
            return data
          })
          setErrors()
          resolve()
        })
      }
    }),
    onRowDelete: (oldData) =>
    new Promise((resolve, reject) => {
      bin_location_del(oldData.id).then(res => {
        setData((prevState) => {
          const data = [...prevState]
          data.splice(data.indexOf(oldData), 1)
          return data
        })
        setErrors()
        resolve()
      })
    }),
  };

  const editable_location = {
    onRowAdd: (newData) =>
    new Promise((resolve, reject) => {
      newData.project_id = id
      location_add(newData).then(res => {
        if (res.error) { errorMessage(res, setErrors); reject(); return }
        setLocs((prevState) => {
          const data = [...prevState]
          data.splice(0, 0, res.data);
          return data
        })
        setErrors()
        fetchData()
        resolve()
      })
    }),
    onRowUpdate: (newData, oldData) =>
    new Promise((resolve, reject) => {
      if (oldData) {
        location_update(newData).then(res => {
          if (res.error) { errorMessage(res, setErrors); reject(); return }
          setLocs((prevState) => {
            const data = [...prevState]
            data[data.indexOf(oldData)] = res.data
            return data
          })
          setErrors()
          fetchData()
          resolve()
        })
      }
    }),
    onRowDelete: (oldData) =>
    new Promise((resolve, reject) => {
      location_del(oldData.id).then(res => {
        if (res.error) { errorMessage(res, setErrors); reject(); return }
        setLocs((prevState) => {
          const data = [...prevState]
          data.splice(data.indexOf(oldData), 1)
          return data
        })
        fetchData()
        resolve()
      })
    }),
  };

  return (<>
    { hasError && <Error hasError={ hasError } setErrors={ () => setErrors() } />}
    <MaterialTable
    components={{
      GroupRow: props => (new GroupRow(props)),
      Groupbar: props => (GroupByBar(props, grouppage)),
      Toolbar: props => (
        <div>
          <MTableToolbar {...props} />
          <IconButton onClick={() => fetchData()} style={{float: 'right'}}><RefreshIcon></RefreshIcon></IconButton>
        </div>),
      EditRow: props => {
        return (
          <MTableEditRow
          {...props}
          onEditingCanceled={(mode, row) => {
            setErrors()
            props.onEditingCanceled(mode);
          }}
          />
        );
      }
    }}
    title={` ${t('container_locaties_project')}  ${proj && proj.name || ''}`}
    localization={localization}
    columns={columns}
    data={data}
    onGroupRemoved={(group) => {
      sessionStorage.removeItem(grouppage + group.field)
    }}
    options={{
      rowStyle: row => ({
        backgroundColor: rowStyles(row), color: 'white'
      }),
      cellStyle: {padding: 2},
      headerStyle: {padding: 6},
      grouping: true,
      addRowPosition: 'first',
      thirdSortClick: false,
    }}
    editable={role_admin ? editable : {}}
    />
    <br/>
    { role_admin &&
      <MaterialTable
      components={{
        GroupRow: props => (new GroupRow(props)),
        Groupbar: props => (GroupByBar(props, "project_location.")),
        EditRow: props => {
          return (
            <MTableEditRow
            {...props}
            onEditingCanceled={(mode, row) => {
              setErrors()
              props.onEditingCanceled(mode);
            }}
            />
          );
        }
      }}
      title={`${t('location')} ${t('project')} ${proj && proj.name || ''}`}
      localization={localization}
      columns={[
        {
          title: t('location'),
          field: 'name',
          defaultGroupOrder: sessionStorage.getItem("project_location." + 'name') || -1,
          render: (row) => { return typeof row === 'string' ? row :<Link to={"/plan/" + row.id}>{row.name}</Link> },
          editComponent: props => (<div className="MuiFormControl-root MuiTextField-root">
            <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
              <input
                className="MuiInputBase-input MuiInput-input"
                type="text"
                value={props.value || ""}
                onChange={e => props.onChange(e.target.value)}
                autoFocus={true}
              />
            </div>
          </div>),
        },
        {
          title: t('level'),
          field: 'level',
          defaultGroupOrder: sessionStorage.getItem("project_location." + 'level') || -1
        }]}
        data={proj_locs}
        onGroupRemoved={(group) => {
          sessionStorage.removeItem("project_location." + group.field)
        }}
        options={{
          // rowStyle: row => ({
          //   backgroundColor: rowStyles(row), color: 'white'
          // }),
          cellStyle: (role_admin ? {padding: 0} : {}),
          headerStyle: (role_admin ? {padding: 6} : {}),
          grouping: true,
          addRowPosition: 'first',
          thirdSortClick: false,
        }}
        editable={role_admin ? editable_location : {}}
        />
      }
      </>
    )
  }
