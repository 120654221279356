import { url, headers } from './url';

// user
export async function user() {
  const response = await fetch(url + 'user/current', {
    method: 'GET',
    headers: headers()
  });
  return response.json()
};

export async function user_update(data) {
  const response = await fetch(url + 'user/current', {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return response.json()
};

// favorite_customers
export async function favorite_customers() {
  return await fetch(url + 'customer?favorite', {
    method: 'GET',
    headers: headers()
  });
};

// favorite_projects
export async function favorite_projects() {
  return await fetch(url + 'project?favorite', {
    method: 'GET',
    headers: headers()
  });
};

// favorite_customers_update
export async function favorite_customers_update(data) {
  const res = await fetch(url + 'customer/' + data.id +'/favorite', {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res
};

// favorite_projects update
export async function favorite_projects_update(data) {
  const res = await fetch(url + 'project/' + data.id +'/favorite', {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res
};

export async function language_api() {
  const response = await fetch(url + 'language', {
    method: 'GET',
    headers: headers()
  });
  return response.json()
};

export async function language_code(data) {
  const response = await fetch(url + 'language/' + data.code, {
    method: 'GET',
    headers: headers()
  });
  return response.json()
};
