import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/footer.js";
const useStyles = makeStyles(styles);

// sticky foot everywhere
export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
        </div>
        <p className={classes.right}>
          <span>
            &copy; {new Date().getFullYear()}{" "}
            SWYCS - DSP Innovation B.V.
          </span>
        </p>
      </div>
    </footer>
  );
}
