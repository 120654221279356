import React, { useRef, useState, useEffect } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useImage from "use-image";
import { bin_locations_by_location } from '../api/bin_location';
import { bin_location_marker_create } from '../api/floorplan';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import Markers from '../components/markers';
import Error from '../components/error.js';
import { errorMessage } from '../utils/error_message';

export default function Floorplan (props) {
  const [markers, setMarkers] = useState([]);
  const [hasError, setErrors] = useState(false);
  const { t, i18n } = useTranslation();
  const [binlocations, setBinLocations] = useState([]);
  const [selected, setSelected] = useState(false);
  const [width, setWidth] = useState(window.outerHeight);
  const [height, setHeight] = useState(window.outerHeight);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const role_admin = sessionStorage.getItem('role') === 'admin';
  const stageRef = useRef();
  const layerRef = useRef();
  let { id } = useParams();

  async function fetchData() {
    setMarkers(props.plans && props.plans[props.index] && props.plans[props.index].bin_location_markers)
    const dropdown = await bin_locations_by_location(id)
      dropdown.json()
        .then(res => {
          // only show bin_locations in dropdown that aren't on the map
          props.plans && props.plans[props.index] && props.plans[props.index].bin_location_markers.map(i => {
            res.data && res.data.splice(res.data.indexOf(res.data.find(x => i.bin_location_id === x.id)), 1);
          })
          setBinLocations(res.data)
        })
  }

  useEffect(() => {
    fetchData()
  },[i18n.lang, props.plans])

  const getImageMetaData = (url) => {
    var img = new Image()
    img.onload = function () {
      setWidth(this.width + 100)
      setHeight(this.height)
    }
    img.src = url
  }

  const MapImage = () => {
    const [image] = useImage(props.plans && props.plans[props.index] && props.plans[props.index].image.url)
    getImageMetaData(props.plans && props.plans[props.index] && props.plans[props.index].image.url)
    return <KonvaImage image={image} />;
  }

  const onMouseDown = e => {
    if (!role_admin) return
    setErrors()
      if (!selected) return
      const point = stageRef.current.getStage().getPointerPosition(),
        x=~~point.x,
        y=~~point.y
      const bin = {
        id: props.plans[props.index] && props.plans[props.index].id,
        bin_location_id: autocompleteValue && autocompleteValue.id,
        x,y
      }
      bin_location_marker_create(bin).then(res => {
        if (res.error) { setSelected(false);  return errorMessage(res, setErrors) }

        setMarkers((prevData) => {
          const data = [...prevData]
          data.splice(0, 0, res.data)
          return data
        })
        setBinLocations((prevData) => {
          const data = [...prevData]
          data.splice(data.indexOf(binlocations.find(x => x.id === res.data.bin_location_id)), 1);
          return data
        })
        setSelected(false)
        setAutocompleteValue(false)
      })
  }

  const onChangeContainerSelect = function (event, newInputValue) {
    setSelected(true)
    setAutocompleteValue(newInputValue)
  }

  return (
  <>
  {role_admin &&
     <Autocomplete
       key={selected}
       value={autocompleteValue}
       id="combo-box-demo"
       options={binlocations}
       onChange={(event, newInputValue) => onChangeContainerSelect(event, newInputValue)}
       getOptionLabel={(option) => `
         ${(option && option.sensor && option.sensor.sensor_id + ' -' || '') }
         ${(option && option.name + ' -' || '') }
         ${(option && option.location && option.location.name + ' -' || '') }
         ${(option && option.location && option.location.level + ' -' || '') }
         ${(option && option.bin_type && option.bin_type.name + ' -' || '') }
         ${(option && option.waste_stream && option.waste_stream.name || '') }
         `}
       style={{ width: 600, paddingBottom: 30, paddingTop: 10}}

       renderInput={(params) => <TextField {...params} label={t("bin_location")} variant="outlined" />}
     />
   }
    <Stage ref={stageRef} width={width} height={height} draggable={(/Mobi|Android/i.test(navigator.userAgent))} onMouseDown={onMouseDown}>
    <Layer ref={layerRef}>
      <MapImage />
      <Markers key={id} stageRef={stageRef} markers={markers} binlocations={binlocations} param={id} plan_id={props.plans && props.plans[props.index] && props.plans[props.index].id} />
    </Layer>
    </Stage>
    { hasError && <Error hasError={ hasError } setErrors={ setErrors } />}
    </>
  )
}
