import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { floorplan_update } from '../api/floorplan';
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from '@material-ui/core/IconButton';
import Error from '../components/error.js';
import { errorMessage } from '../utils/error_message';

export default function CardInput(props) {
  const [input, setInput] = useState(props.plan.name)
  const [show, setShow] = useState(false)
  const [hasError, setErrors] = useState(false)
  const role_admin = sessionStorage.getItem('role') === 'admin';

  const updateFloorplanName = e => {
    if (!props.plans[props.i]) return
    new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('name', input)
      floorplan_update(formData, props.plans[props.i] && props.plans[props.i].id).then(res => {
        if (res.error) { errorMessage(res, setErrors); reject(); return }
        props.plans[props.i].name = input
        setShow(false)
        resolve()
      })
    })
  }

  return (<>
    { hasError && <Error hasError={ hasError } setErrors={ setErrors } />}
    { !show &&
    <>
      {props.plan.name}
      {role_admin &&
      <IconButton onClick={() => setShow(true)}>
        <EditIcon></EditIcon>
      </IconButton>
      }
    </>
    }
    { show && <>
      <TextField id={props.plan.id} value={input}
      onChange={(event) => {setInput(event.target.value)}} />
      <IconButton onClick={(event) => {updateFloorplanName(event.target.value);}}>
      <SaveIcon></SaveIcon>
      </IconButton></>
    }
  </>);
}
