import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Footer from "../components/footer.js";
import Sidebar from "../components/sidebar.js";
import Head from "../components/head.js";
import { routes } from "../routes.js";
import styles from "../assets/material.js";
import logo from "../assets/img/Renewi_logo.svg";
import { remember } from "../utils/remember";
import { useTranslation } from 'react-i18next';

const currentUser = remember()

let routeKey = 0;

const buildRoute = route => {
  let result = [];
  result.push(
    <Route
      key={routeKey++}
      exact
      path={route.path}
      component={route.component}
    />
  );
  if ("subRoutes" in route) {
    route.subRoutes.map(prop => {
      return result.push(buildRoute(prop));
    });
  }
  return result;
}

const switchRoutes = (
  <Switch>
    {routes().map(prop => {
      if (prop.path !== '/login' && !currentUser) return '';
      return buildRoute(prop);
    })}
    <Redirect from="/" to="/login" />
  </Switch>
);

const translate = (r, {t}) => {
  r.map(i => {
    return i.name = t(i.name)
  })
  return r
}

const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = (e) => {
    if (typeof e === 'boolean') return setMobileOpen(e)
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.wrapper}>
    {currentUser ?
      <Sidebar
        routes={translate(routes(), {t})}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        {...rest}
      />
      : ''}
      <div className={currentUser ? classes.mainPanel : ''} ref={mainPanel}>
      <Head
        routes={routes()}
        handleDrawerToggle={handleDrawerToggle}
        {...rest}
      />
        { <div>
          <Hidden only={['md', 'sm', 'xs']}>
            <div className={classes.content}>
              <div className={classes.container}>
              {switchRoutes}
              </div>
            </div>
          </Hidden>
          <Hidden only={['lg', 'xl']}>
            {switchRoutes}
          </Hidden>
            </div>
        }
        { <Footer />}
      </div>
    </div>
  );
}
