import React, { useState, useEffect } from "react";
import MaterialTable, { MTableEditRow } from 'material-table';
import GroupByBar from './group_by_bar';
import GroupRow from './group_row';
import { Link } from 'react-router-dom';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from '@material-ui/core/TextField';
import { customer } from '../api/customer';
import { project_by_customer, project_add, project_update, project_del } from '../api/project';
import { favorite_projects_update } from '../api/user';
import { errorMessage } from '../utils/error_message';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import Error from '../components/error.js';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { tabindex } from '../utils/tabindex';

const useStyles = makeStyles({
  input: {
    "&:invalid": {
      border: "red solid 2px"
    }
  }
})

export default function Projects() {
  const grouppage = 'projects.'
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [cust, setCust] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumn] = useState([]);
  const { id } = useParams();
  const role_admin = sessionStorage.getItem('role') === 'admin';

  async function fetchData() {
    const [c, p] = await Promise.all([customer(id), project_by_customer(id)])
    setCust(await c.json().then(r => r.data))
    setData(await p.json().then(r => r.data))
    setColumn([
      { title: t('favorite'), 
        field: 'favorite',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'favorite') || -1,
        editComponent: () => <></>,
        render: (row) => <Tooltip title={t('favorite')}><IconButton onClick={() => {
          row.favorite = row.favorite ? false : true
          setData((prevData) => {
            const data = [...prevData];
            data[data.indexOf(row)] = row;
            return data;
          })
          favorite_projects_update(row).then(res => { if (res.error) return errorMessage(res, setErrors) })
        }}>
        { (typeof row !== 'object'? row : row.favorite) ? <Star /> : <StarBorderIcon/>}
        </IconButton></Tooltip>
      },
      { title: t('project'), 
        field: 'name', 
        render: row => row.id && <Link to={ '/customer/' + (row.customer_id ? 'project/' : '') + row.id}>{row.name}</Link> || row,
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'name') || -1,
        editComponent: props => (<div className="MuiFormControl-root MuiTextField-root">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
            <input
              className="MuiInputBase-input MuiInput-input"
              type="text"
              value={props.value || ""}
              onChange={e => props.onChange(e.target.value)}
              autoFocus={true}
            />
          </div>
        </div>)
      },
      { title: t('adres'), field: 'address', defaultGroupOrder: sessionStorage.getItem(grouppage + 'address') || -1, },
      { title: t('postal_code'), field: 'postal_code',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'favorite') || -1,
        editComponent: props => { return (
            <TextField
            inputProps={{
              value:props.value || "",
              maxLength: 6,
              className: classes.input,
              pattern:"[A-Z0-9]{1,6}",
              onChange:e => props.onChange(e.target.value)
              }}
            />)
      },},
      { title: t('city'), field: 'city', defaultGroupOrder: sessionStorage.getItem(grouppage + 'city') || -1,},
    ])
    tabindex()
  }

  useEffect(() => {
    fetchData()
  }, [i18n.lang, id, t])

  const editable = {
    onRowAdd: (newData) =>
      new Promise((resolve, reject) => {
          newData.customer_id = id
          project_add(newData).then(res =>{
          if (res.error) { errorMessage(res, setErrors); reject(); return }
          setData((prevData) => {
            const data = [...prevData]
            data.splice(0, 0, res.data)
            return data
          })
          setErrors()
          resolve()
        })
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        project_update(newData).then(res =>{
          if (res.error) { errorMessage(res, setErrors); reject(); return }
          setData((prevData) => {
            const data = [...prevData]
            data[data.indexOf(oldData)] = res.data
            return data
          })
          setErrors()
          resolve()
        })
      }),
    onRowDelete: (oldData) =>
      new Promise((resolve, reject) => {
        project_del(oldData.id).then(res => {
          if (res.error) { errorMessage(res, setErrors); reject(); return }
          setData(prevData => {
            const data = [...prevData];
            data.splice(data.indexOf(oldData), 1);
            return data
          })
          setErrors()
          resolve()
        })
      }),
  }

        return (<>
                { hasError && <Error hasError={ hasError } setErrors={ () => setErrors() } />}
                <MaterialTable
                  components={{
                    GroupRow: props => (new GroupRow(props)),
                    Groupbar: props => (GroupByBar(props, grouppage)),
                    EditRow: props => {
                      return (
                        <MTableEditRow
                          {...props}
                          onEditingCanceled={(mode, row) => {
                            setErrors()
                            props.onEditingCanceled(mode);
                          }}
                        />
                      );
                    }
                  }}
                  title={`${t('customer')} : ${cust && cust.name || ''}`}
                  localization={{
                    pagination: {
                          labelDisplayedRows: '{from}-{to} of {count}',
                          labelRowsSelect: t("labelRowsSelect"),
                          labelRowsPerPage: t("labelRowsPerPage"),
                          firstAriaLabel: t("firstAriaLabel"),
                          firstTooltip: t("firstTooltip"),
                          previousAriaLabel: t("previousAriaLabel"),
                          previousTooltip: t("previousTooltip"),
                          nextAriaLabel: t("nextAriaLabel"),
                          nextTooltip: t("nextTooltip"),
                          lastAriaLabel: t("lastAriaLabel"),
                          lastTooltip: t("lastTooltip"),
                      },
                      toolbar: {
                          nRowsSelected: '{0} row(s) selected',
                          searchTooltip: t("search"),
                          searchPlaceholder: t("search"),
                          exportTitle: t("export"),
                      },
                      header: {
                          actions: t("action")
                      },
                      grouping: {
                        placeholder: t('groupingPlaceholder'),
                      },
                   body: {
                     emptyDataSourceMessage: t("no_records"),
                     filterRow: {
                         filterTooltip: t("filter")
                     },
                     editRow: {
                       saveTooltip: t("save"),
                       cancelTooltip: t("cancel"),
                       deleteText: t("delete_text")
                     },
                     addTooltip: t("add"),
                     deleteTooltip: t("delete"),
                     editTooltip: t("edit")
                   }
                  }}
                  columns={columns}
                  data={data}
                  onGroupRemoved={(group) => {
                    sessionStorage.removeItem(grouppage + group.field)
                  }}
                  options={{
                    grouping: true,
                    cellStyle: ({padding: 0}),
                    headerStyle: ({padding: 6}),
                    addRowPosition: 'first',
                    thirdSortClick: false,
                  }}
                  editable={ role_admin ? editable : {}}
                />
        </>)
}
