export function formatDate(dateString) {
    if (!dateString) {
        return ''
    }
    var date = new Date(dateString + 'Z');
    return date.toLocaleDateString('en-GB', {
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    }).replace(',', '')
}
