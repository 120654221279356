import { url, headers } from './url';

export async function notifications(data) {
  let param = ''
  if (data.notification_type && data.notification_type !== 'all') param += `notification_type=${data.notification_type}`
  if (data.type && data.type !== 'all') param += `&type=${data.type}`
  if (data.level !== 'all') param += `&level=${data.level}`
  if (typeof data.resolved === 'boolean') param += `&resolved=${data.resolved}`
  param+=`&per_page=1000`
  const res = await fetch(url + 'notification?' + param, {
    method: 'GET',
    headers: headers(),
  });
  return res
};
