import React from "react";
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function Error({hasError, setErrors}) {
  return (<>
    <Snackbar open={true} TransitionComponent={TransitionDown}>
    <Alert
      variant="filled"
      severity="error"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => setErrors()}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      >
      {hasError}
    </Alert>
    </Snackbar>
    <br/>
</>)
}
