import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "../assets/sidebar.js";
import { Redirect } from "react-router-dom";
import { remember } from "../utils/remember";
import { logout } from "../utils/logout";

const currentUser = remember()
const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const classes = useStyles();

  function activeRoute(route) {
    return window.location.hash.substr(1);
  }

  const { color, logo, routes } = props;
  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        var listItemClasses = classNames({
            [" " + classes[color]]: activeRoute(prop)
          });
        if(!currentUser) {
          return (<Redirect to='/login' />)
        }
        if(!prop.isMenuItem)
          return null;
        return (
          <NavLink
            hidden={true}
            key={key}
            to={prop.path}
            className={' ' + classes.item}
            activeClassName="active"
            onClick={() => {
              props.handleDrawerToggle(false)
              if (prop.attr === "sign_out") logout()
            }}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              <prop.icon
                className={classNames(classes.itemIcon, listItemClasses, {
                  [classes.itemIcon]: false
                })}
              />
              <ListItemText
                primary={prop.name}
                className={classNames(classes.itemText, listItemClasses, {
                  [classes.itemText]: false
                })}
                disableTypography={true}
              />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <div className={classNames(classes.logoLink, { [classes.logoLink]: false })}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaper]: false
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {links}
          </div>
            <div
            className={classes.background}
            />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={"right"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: false
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
            <div
              className={classes.background}
            />
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  logo: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
