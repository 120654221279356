import Dashboard from "@material-ui/icons/Dashboard";
import People from "@material-ui/icons/People";
import Lock from "@material-ui/icons/Lock";
import SettingsIcon from "@material-ui/icons/Settings";
import DeleteSweep from "@material-ui/icons/DeleteSweep";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Notes from "@material-ui/icons/Notes";
import Equalizer from "@material-ui/icons/Equalizer";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Language from "@material-ui/icons/Language";
import LockOutlined from '@material-ui/icons/LockOutlined';
import Home from "./views/home";
import Projects from "./views/projects"
import Login from "./views/login";
import Plan from "./views/plan";
import Project from "./views/project_locations";
import Customers from "./views/customers";
import Locations from "./views/bin_locations";
import Sensors from "./views/sensors";
import Report from "./views/report";
import Mutations from "./views/mutations";
import Notifications from "./views/notifications";
import Access from "./views/access_control";
import Settings from "./views/settings";

let role_admin = sessionStorage.getItem('role') === 'admin';


export function routes() {

  const routes = [
  {
    path: "/home",
    name: "home",
    icon: Dashboard,
    component: Home,
    isMenuItem: true
  },
  {
    path: "/customers",
    name: "customers",
    icon: People,
    component: Customers,
    isMenuItem: true,
    subRoutes: [
      {
        path: "/customer/:id",
        isMenuItem: false,
        component: Projects
      },
      {
        path: "/customer/project/:id",
        isMenuItem: false,
        component: Project
      }
    ]
  },
  {
    path: "/locations",
    name: "bin_locations",
    icon: DeleteSweep,
    component: Locations,
    isMenuItem: true
  },
  {
    path: "/sensors",
    name: "sensors",
    icon: Equalizer,
    component: Sensors,
    isMenuItem: true
  },
  {
    path: "/notification",
    name: "notification",
    icon: NotificationsIcon,
    component: Notifications,
    isMenuItem: true
  },
  {
    path: "/reports",
    name: "reports",
    icon: Equalizer,
    component: Report,
    isMenuItem: role_admin
  },
  {
    path: "/access_control",
    name: "access_control",
    icon: Lock,
    component: Access,
    isMenuItem: role_admin
  },
  {
    path: "/settings",
    name: "settings",
    icon: SettingsIcon,
    component: Settings,
    isMenuItem: role_admin
  },
  {
    path: "/mutation_logs",
    name: "mutation_logs",
    icon: Notes,
    component: Mutations,
    isMenuItem: role_admin
  },
  {
    path: "/plan/:id",
    name: "Language",
    icon: Language,
    component: Plan,
    isMenuItem: false
  },
  {
    path: "/signout",
    name: "sign_out",
    attr: "sign_out",
    icon: ExitToApp,
    isMenuItem: true,

  },
  {
    path: "/login",
    name: "sign_out",
    icon: LockOutlined,
    component: Login,
    isMenuItem: false
  }
];
 return routes
}
