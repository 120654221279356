import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import CollectionsIcon from "@material-ui/icons/Collections";
import CloseIcon from "@material-ui/icons/Close";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Floorplan from "./floorplan";
import Error from '../components/error.js';
import CardInput from '../components/input.js';
import { makeStyles } from '@material-ui/core/styles';
import { floorplan } from '../api/floorplan';
import { useParams } from "react-router-dom";
import { location_floorplan } from '../api/location';
import { floorplan_delete } from '../api/floorplan';
import { errorMessage } from '../utils/error_message';
import { useConfirm } from "material-ui-confirm";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  item: {
    display: "inline-block",
    paddingLeft: 0,
    paddingRight: 10,
    paddingBottom: 5,
    paddingTop: 5,
  },
  plancard: {
    minWidth: 280,
    minHeight: 360,
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}))

export default function ImageUpload() {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const role_admin = sessionStorage.getItem('role') === 'admin';
  const [hasError, setErrors] = useState(false);
  const [newName, setNewName] = useState('');
  const [index, setIndex] = useState(0);
  const [plans, setAllPlans] = useState([]);
  const [mainState, setMainState] = useState("gallery");
  const [name, setName] = useState('');
  let { id } = useParams();

  async function fetchData() {
    const plan = await location_floorplan(id)
      plan.json()
        .then(res => {
          setAllPlans(res.data)
          setName(res.data && res.data[index] && res.data[index].name)
        })
  }

  useEffect(() => {
    fetchData()
  },[index])

  const handleUploadClick = event => {
    if ( event.target.files[0]) {
    const formData = new FormData()
    formData.append('location_id', id)
    formData.append('name', newName)
    formData.append('image', event.target.files[0])
    new Promise((resolve, reject) => {
    floorplan(formData).then(res => {
      if (res && res.error) { errorMessage(res, setErrors); document.getElementById('contained-button-file').value = ''; reject(); return }
      setAllPlans((prevState) => {
        const data = [...prevState]
        data.push(res.data);
        return data
      })
      setIndex(plans.length)
      resolve()
      setErrors()
      setMainState("gallery")
      setNewName('')
      document.getElementById('contained-button-file').value = '';
    })

    })
   }
  }

  const handleGalleryClick = event => {
    if (mainState === "gallery") return setMainState("intial")
    if (plans && plans.length) setMainState("gallery")
  }

  const renderInitialState = () => {
    return (
      <div className={classes.root}>
      <div className={classes.item}>
      { role_admin && <>
      <TextField id="new-name" value={newName} onChange={(event) => {setNewName(event.target.value)}} />
      <input
      style={{display: "none"}}
      id="contained-button-file"
      type="file"
      onChange={handleUploadClick}
      />
      <IconButton>
      <label htmlFor="contained-button-file">
      <AddPhotoAlternateIcon />
      </label>
      </IconButton>
      <br/>
      </>}
      <IconButton onClick={handleGalleryClick}>
      <CollectionsIcon />
      </IconButton>
      </div>
      </div>
    )
  }

  const handleThumbnailClick = (plan, i) => {
    setIndex(i)
  }

  const onRemovePlanImage = (plan, i) => {
    new Promise((resolve, reject) => {
      confirm({ title: t('attention'),
                description: t('delete_plan'),
                confirmationText: t('are_you_sure'),
                cancellationText: t('cancel_dialog'),
        }).then((e) => {

      floorplan_delete(plan.id).then(res => {
        if (res.error) { errorMessage(res, setErrors); reject(); return }
        setAllPlans(prevData => {
          const data = [...prevData];
          data.splice(data.indexOf(plan), 1);
          setName((data && data[0] && data[0].name) || '')
          setIndex(0)
          setErrors()
          resolve()
          return data
        })
      })
    })
  }).catch((e) => {})
  }

  const renderGalleryState = () => {
    const listItems = plans && plans.map((plan, i) => (<>
      <div key={i} className={classes.item}>
      <Card className={classes.plancard}>
        <CardContent>
        <CardInput key={i} plans={plans} plan={plan} i={i} />
        </CardContent>
        <CardMedia
         className={classes.media}
         image={plan.image.url}
         title={ plan.name }
         onClick={() => handleThumbnailClick(plan, i)}
        />
        <CardContent>
        { role_admin &&
        <IconButton onClick={() => onRemovePlanImage(plan, i)}>
          <CloseIcon />
        </IconButton>
      }
        </CardContent>
      </Card>
      </div>
    </>));

    return (
      <>
      {listItems}
      </>
    );
  }


    return (<>
              <h2>
                {name}
              </h2>
              <br/>
              { hasError && <Error hasError={ hasError } setErrors={ () => setErrors() } />}
              { renderInitialState() }
              { (mainState === "gallery" &&
                <>
                <br/>
                <div className={classes.root}>
                  {renderGalleryState()}
                </div>
                </>
              )}
              <Floorplan key={index} index={index} plans={plans} />
            </>)
}
