import { url } from './url';

let data = {
  grant_type:'password',
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_KEY,
}

export async function login(user, pass) {
  data.username = user
  data.password = pass
  const response = await fetch(url + 'oauth/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json()
}
