import { url, headers } from './url';

export async function project_locations(id) {
  return await fetch(url + 'project/' + id + '/location', {
    method: 'GET',
    headers: headers()
  });
};

export async function location_floorplan(id) {
  return await fetch(`${url}location/${id}/floorplan`, {
    method: 'GET',
    headers: headers()
  });
}

export async function location() {
  return await fetch(`${url}location`, {
    method: 'GET',
    headers: headers()
  });
}

export async function location_add(data) {
  const res = await fetch(`${url}location`, {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function location_update(data) {
  const res = await fetch(url + 'location/' + data.id, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function location_del(id) {
  const res = await fetch(url + 'location/' + id, {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json()
};
