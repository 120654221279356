export function fr() {
  return {
    "The type must be a string.": "Champ de filtre obligatoire",
    "Location with this name and level already exists.": "L'endroit avec ce nom et ce niveau existe déjà.",
    "date": "Date",
    "resolved": "Résolu",
    "edit_profile": "Editer le profil",
    "complete_profile": "Complète ton profil",
    "username": "Nom d'utilisateur",
    "role": "Rôle",
    "update_profile": "Mettre à jour le profil",
    "en": "Anglais",
    "nl": "Néerlandais",
    "fr": "Français",
    "error": "Erreur",
    "warning": "Attention",
    "info": "Information",
    "log": "Journal",
    "all": " - ",
    "unresolved": "Non résolu",
    "bin_volume": "Volume du bac",
    "battery_level": "Niveau de batterie",
    "connection_status_ok": "en ligne",
    "connection_status_error": "hors ligne",
    "connection_status_": "pas de capteur",
    "sensor_status": "Capteur statut",
    "sensor_offline": "Capteur hors ligne",
    "sensor_online": "Capteur en ligne",
    "sensor_removed": "Capteur retiré",
    "sensor_added": "Capteur ajouté",
    "replace_battery": "Remplacer la batterie",
    "empty_bin_timed": "Conteneur vide, le temps a passé",
    "empty_bin_full": "Récipient vide, le récipient est plein",
    "check_sensor": "Vérifier le capteur",
    "bin_message_type": "Notification",
    "sensor_communication": "Communication du capteur",
    "customer": "Client",
    "customers": "Les clients",
    "country": "Pays",
    "language": "Langue",
    "project": "Projet",
    "projects": "Projets",
    "adres": "Adresse",
    "postal_code": "Code postal",
    "city": "Ville",
    "favorite": "Préféré",
    "favorites": "Préféré",
    "sign_out": "Déconnexion",
    "settings": "Réglages",
    "mutation_logs": "Journaux de mutation",
    "access_control": "Contrôle d'accès",
    "reports": "Rapports",
    "notification": "Notification",
    "work_in_progress": "Travail en cours",
    "container": "Récipient",
    "containers": "Conteneurs",
    "location": "Emplacement",
    "locations": "Emplacements",
    "bin_locations": "Emplacements des conteneurs",
    "bin_location": "Emplacement du conteneur",
    "resource": "Ressource",
    "created": "Créé",
    "deleted": "Supprimé",
    "updated": "Mise à jour",
    "data": "Les données",
    "home": "Accueil",
    "type": "Type",
    "container_locaties_project": "Projet de localisation de conteneurs",
    "sensor": "Capteur",
    "sensors": "Capteurs",
    "protocol_type": "Type de protocole",
    "calibration_settings_int": "Paramètres d'étalonnage int",
    "calibration_settings_float": "Paramètres d'étalonnage float",
    "calibration_settings_string": "Paramètres d'étalonnage string",
    "interval_settings_int": "interval_settings_int",
    "interval_settings_float": "interval_settings_float",
    "interval_settings_string": "interval_settings_string",
    "interval_settings_data_interval": "Intervalle de données",
    "level": "Sol",
    "bin_type": "Type de conteneur",
    "bin_types": "Types de conteneurs",
    "waste_stream": "Flux de déchets",
    "volume_level": "Niveau de volume",
    "save": "Sauver  (ctrl+shift+s)",
    "save_settings": "Sauver",
    "cancel_dialog": "Annuler",
    "cancel": "Annuler (ctrl+x)",
    "attention": "Attention!",
    "are_you_sure": "Oui, je suis sûr",
    "delete_sensor_1": "Voulez-vous vraiment déconnecter le capteur",
    "delete_sensor_2": "?",
    "delete_plan": "Voulez-vous vraiment supprimer ce plan d'étage?",
    "delete_text": "Voulez-vous vraiment supprimer cette ligne?",
    "delete_marker": "Voulez-vous vraiment supprimer ce marqueur?",
    "add": "Ajouter (ctrl+q)",
    "delete": "Supprimer",
    "edit": "Éditer",
    "no_records": "Aucun enregistrement à afficher",
    "action": "Actions",
    "mutation_action": "Actions",
    "search": "Chercher",
    "export": "Exportation",
    "labelRowsSelect": "Lignes",
    "labelRowsPerPage": "Lignes par page:",
    "firstAriaLabel": "Première page",
    "firstTooltip": "Première page",
    "previousAriaLabel": "Page précédente",
    "previousTooltip": "Page précédente",
    "nextAriaLabel": "Page suivante",
    "nextTooltip": "Page suivante",
    "lastAriaLabel": "Dernière page",
    "lastTooltip": "Dernière page",
    "groupingPlaceholder": "Faites glisser les en-têtes ici pour les regrouper",
    "sensor_id": "Capteur",
    "sensor_type": "Type de capteur",
    "protocol": "Protocole",
    "status": "Statut",
    "connection": "Connexion qualité",
    "connection_quality": "Connexion kwaliteit",
    "sensor_state_connected": "Liée",
    "sensor_state_disconnected": "Débranché",
    "connection_error": "Erreur de connexion, serveur API non disponible",
    "last_seen": "Dernière vue",
    "last_downlink": "Dernière liaison descendante envoyée",
    "location_level": "Emplacement au sol",
    "from": "Période de",
    "to": "Période jusqu'à",
    "sensor_location": "Emplacement du capteur",
    "unlink": "Dissocier le capteur",
    "no_sensors": "Pas de capteurs",
    "no_sensor": "Pas de capteur",
    "link_sensor": "Link sensor",
    "email": "Adresse électronique",
    "password": "Mot de passe",
    "remember": "Souviens-toi de moi",
    "login": "Se connecter",
    "sign_in": "Se connecter",
    "floorplan": "Floorplan",
    "The given data was invalid.": "Les données fournies n'étaient pas valides.",
    "The location id field is required.": "Le champ d'emplacement est obligatoire.",
    "The waste stream id field is required.": "Le champ flux de déchets est obligatoire.",
    "The name field is required.": "Il est requis de compléter le champ correspondant au nom.",
    "The address field is required.": "Le champ d'adresse est obligatoire.",
    "The bin type id field is required.": "Le champ d'emplacement est obligatoire.",
    "The postal code field is required.": "Le champ du code postal est obligatoire.",
    "The city field is required.":"Le champ ville est obligatoire.",
    "The image field is required.": "Le champ image est obligatoire.",
    "The country id field is required.": "Il est obligatoire de sélectionner un pays.",
    "The name has already been taken.": "Le nom a déjà été pris.",
    "The bin location id has already been taken.": "L'emplacement du conteneur existe déjà sur cette carte",
    "The x field is required.": "Aucune position x sélectionnée.",
    "The y field is required.": "Aucune position y sélectionnée.",
    "Floorplan with identifier(s) undefined not found.": "Aucune carte trouvée.",
    "The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.":
    "L'octroi d'autorisation fourni (par exemple, le code d'autorisation, les informations d'identification du propriétaire de la ressource) ou le jeton d'actualisation n'est pas valide, a expiré, a été révoqué, ne correspond pas à l'URI de redirection utilisé dans la demande d'autorisation ou a été émis pour un autre client.",
    "No support for protocol lora": "Pas de support pour le protocole lora.",
    "Client authentication failed":"Échec de l'authentification du client",
    "default": "Défaut",
    "custom": "Personnalisé",
    "user": "Utilisateur",
    "users": "Utilisateurs",
    "admin": "Admin",
    "notification_field": "Notification",
    "urgency": "Niveau d'urgence",
    "notification_type": "Gentil",
    "creation_date": "Date de création",
    "battery_error": "Erreur de batterie",
    "battery_warning": "Avertissement de batterie",
    "time_error": "Mauvais temps",
    "time_warning": "Avertissement de temps",
    "volume_error": "Erreur de volume",
    "volume_warning": "Avertissement de volume",
    "The roles field is required.": "Le champ de rôle est obligatoire.",
    "The email field is required.": "Le champ email est obligatoire.",
    "The password field is required.": "Le champ du mot de passe est obligatoire.",
    "The email has already been taken.": "L'adresse e-mail est déjà utilisée.",

    "hour": "heure",
    "minutes": "minutes",

    "bin_location_setting": "Réglage de l'emplacement du conteneur",
    "default_bin_location_setting": "Paramètre d'emplacement de conteneur global",

    "global_bin_location_settings": "Paramètres d'emplacement du conteneur",
    "change_global_bin_location_settings": "Configurer les paramètres globaux pour les emplacements de conteneurs",

    "waste_streams": "Les flux de déchets",

    "Unable to remove the waste stream because the waste stream still has active bin location(s)" : "Impossible d'éliminer le flux de déchets. Le flux de déchets est utilisé dans les emplacements de conteneurs actifs",

    "max_volume_cm": "Volume mesuré à vide (cm)",
    "min_volume_cm": "Volume mesuré lorsqu'il est plein (cm)",
    "floorplan_bin_marker": "Marqueur de bac d'étage",
    "bin_location_sensor": "Capteur de bac",
    "user-roles": "Rôles utilisateur",

    "The postal code may only contain letters and numbers." : "Le code postal ne peut contenir que des lettres et des chiffres."
  }
}
