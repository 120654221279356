import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import CustomInput from "../components/CustomInput/CustomInput.js";
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import Button from "../components/CustomButtons/Button.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import CardFooter from "../components/Card/CardFooter.js";
import Error from '../components/error.js';
import { useTranslation } from 'react-i18next';
// import { language_api, user, user_update } from '../api/user';
import { bin_location_settings, bin_location_settings_update } from '../api/setting';
import { errorMessage } from '../utils/error_message';
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function GlobalBinLocationSettings() {
  const { t, i18n } = useTranslation();
  const [saved, setSaved] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [settings, setSettings] = React.useState({});

  const fetchData = () => {
    bin_location_settings().then(res => {
      if (res.error) return errorMessage(res, setErrors)
      setSettings(res.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const editSettings = (event) => {
    bin_location_settings_update({
      volume_warning: settings.volume_warning,
      volume_error: settings.volume_error,
      battery_warning: settings.battery_warning,
      battery_error: settings.battery_error,
      time_warning: settings.time_warning,
      time_error: settings.time_error,
    }).then((res) => {
      if (res.error) return errorMessage(res, setErrors)
      setSettings(res.data)
    })
  }

  const classes = useStyles();
  return (
    <div>
      { hasError && <Error hasError={ hasError } setErrors={ setErrors } />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>{t('global_bin_location_settings')}</h4>
              <p className={classes.cardCategoryWhite}>{t('change_global_bin_location_settings')}</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText={t("volume_warning")}
                    id="volume_warning"
                    formcontrolprops={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: settings.volume_warning && settings.volume_warning.value || '',
                      onChange:(e) => {
                        if(e.target.value === '' || RegExp('^[1-9][0-9]?$|^100$').test(e.target.value)) {
                          let newEdit = { ...settings };
                          newEdit.volume_warning.value = e.target.value;
                          setSettings(newEdit)
                        }
                      },
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      type: "number"
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText={t('volume_error')}
                    id="volume_error"
                    formcontrolprops={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: settings.volume_error && settings.volume_error.value || '',
                      onChange:(e) => {
                        if(e.target.value === '' || RegExp('^[1-9][0-9]?$|^100$').test(e.target.value)) {
                          let newEdit = { ...settings };
                          newEdit.volume_error.value  = e.target.value;
                          setSettings(newEdit)
                        }
                      },
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      type: "number"
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText={t('time_warning')}
                    id="time_warning"
                    formcontrolprops={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: (settings.time_warning && settings.time_warning.value / 3600) || '',
                      onChange:(e) => {
                        if(e.target.value === '' || RegExp('^[1-9][0-9]*$').test(e.target.value)) {
                          let newEdit = { ...settings };
                          newEdit.time_warning.value = (e.target.value !== '' ? e.target.value * 3600 : e.target.value);
                          setSettings(newEdit)
                        }
                      },
                      endAdornment: <InputAdornment position="end">{t('hour')}</InputAdornment>,
                      type: "number"
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText={t('time_error')}
                    id="time_error"
                    formcontrolprops={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: settings.time_error && settings.time_error.value / 3600 || '',
                      onChange:(e) => {
                        if(e.target.value === '' || RegExp('^[1-9][0-9]*$').test(e.target.value)) {
                          let newEdit = { ...settings };
                          newEdit.time_error.value = (e.target.value !== '' ? e.target.value * 3600 : e.target.value);
                          setSettings(newEdit)
                        }
                      },
                      endAdornment: <InputAdornment position="end">{t('hour')}</InputAdornment>,
                      type: "number"
                    }}
                  />
                  </GridItem>
                </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText={t('battery_warning')}
                    id="battery_warning"
                    formcontrolprops={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: settings.battery_warning && settings.battery_warning.value || '',
                      onChange:(e) => {
                        if(e.target.value === '' || RegExp('^[1-9][0-9]?$|^100$').test(e.target.value)) {
                          let newEdit = { ...settings };
                          newEdit.battery_warning.value = e.target.value;
                          setSettings(newEdit)
                        }
                      },
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      type: "number"
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText={t('battery_error')}
                    id="battery_error"
                    formcontrolprops={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: settings.battery_error && settings.battery_error.value || '',
                      onChange:(e) => {
                        if(e.target.value === '' || RegExp('^[1-9][0-9]?$|^100$').test(e.target.value)) {
                          let newEdit = { ...settings };
                          newEdit.battery_error.value = e.target.value;
                          setSettings(newEdit)
                        }
                      },
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      type: "number"
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={(event) => {
                editSettings(event)
                setSaved(true)
                setTimeout(() => setSaved(false), 3000)
              }} >{t('save_settings')}</Button>
              { saved && <IconButton style={{maxWidth: 50}} ><Check style={{color: 'green'}}/></IconButton> }
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
