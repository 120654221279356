import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import {nl} from "./translations/nl.js"
import {en} from "./translations/en.js"
import {fr} from "./translations/fr.js"

const resources = {
  nl: { translation: nl() },
  en: { translation: en() },
  fr: { translation: fr() },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "nl",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  i18n.on('languageChanged', function(lng) {
    i18n.lang = lng
  })

  export default i18n;
