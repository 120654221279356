import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import GroupByBar from './group_by_bar';
import GroupRow from './group_row';
import Avatar from '@material-ui/core/Avatar';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import { Link } from 'react-router-dom';
import { batteryStyles, levelStyles, rowStyles } from '../utils/row_status_colors';
import { bin_location_detailed } from '../api/bin_location';
import Error from '../components/error.js';
import { useTranslation } from 'react-i18next';
import { formatBinLocationData } from '../utils/bin_location';

export default function Locations() {
  const grouppage = 'bin_locations.'
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumn] = useState([]);
  const [defaultCustomerSort, setDefaultCustomerSort] = useState(sessionStorage.getItem('direction') || 'asc');

  async function fetchData() {
    const res = await bin_location_detailed();
    res
      .json()
      .then(res => {
        let data = res && res.data && res.data.map(i => {
          return formatBinLocationData(i)
        })
        // setData(sortByProp(data, 'name', defaultCustomerSort, 'customer'))
        setData(data)
        setColumn([
          {
            title: t('customer'),
            field: 'customer.name',
            sorting: true,
            defaultSort: 'asc',
            // customSort: ( a, b ) => {
            //   // first empty
            //   setDefaultCustomerSort(defaultCustomerSort === 'asc' ? 'desc' : 'asc');
            //   sessionStorage.setItem('direction', defaultCustomerSort === 'asc' ? 'desc' : 'asc');
            //   if (a && a.customer) return a.customer.name > b.customer.name ? 1 : -1
            //   return -1
            // },
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'customer.name') || -1,
            render: (row) => row && row.customer && row.customer.id && <Link to={"/customer/" + row.customer.id}>{row.customer.name}</Link> || row,
          },
          {
            title: t('project'),
            field: 'project.name',
            render: (row) => row && row.project && row.project.id && <Link to={"/customer/project/" + row.project.id}>{row.project.name}</Link> || row,
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'project.name') || -1,
          },
          {
            title: t('location'),
            field: 'location.name',
            render: (row) => row && row.location && row.location.name && <Link to={"/plan/" + row.location.id}>{row.location.name}</Link> || row,
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'location.name') || -1,
          },
          {
            title: t('sensor_location'),
            field: 'name',
            defaultSort: 'asc',
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'name') || -1,
          },
          {
            title: t('level'),
            field: 'location.level',
            // render: (row) => row && row.location && row.location.level || row,
            //  render: (row, renderType) => { 
            //   if ( typeof(row) === 'undefined') return
            //   if (renderType === 'row') return row.location && row.location.level ? row.location.level-0 : ""
            //   if (renderType === 'group') {
            //     return row-0
            //   }
            // },
            customSort: (a, b, renderType) => {
              if (renderType === 'row') {
                if (!a.location || !a.location.level) return -1;
                if (!b.location || !b.location.level) return 1;
                var x = (a.location && a.location.level) - 0
                var y = (b.location && b.location.level) - 0
                return x < y ? -1 : x > y ? 1 : 0;
              }
              if (renderType === 'group') {
                if (typeof (a) === 'undefined') return;
                if (typeof (b) === 'undefined') return;
                if (!a) return -1;
                if (!b) return 1;
                var x = a - 0
                var y = b - 0
                return x < y ? -1 : x > y ? 1 : 0;
              }
            },
            editComponent: () => <></>,
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'location.level') || -1,
          },
          {
            title: t('bin_type'),
            field: 'bin_type.name',
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'bin_type.name') || -1,
          },
          {
            title: t('waste_stream'),
            field: 'waste_stream.name',
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'waste_stream.name') || -1,
          },
          {
            title: t('battery_level'),
            field: 'battery_level',
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'battery_level') || -1,
            editComponent: () => <></>,
            render: (row, renderType) => {
              var battery_level = '-1'
              if (renderType === 'row') {
                battery_level = row.battery_level
                return <Link to={'/sensors'}>{battery_level >= '0' ? <Avatar style={{ margin: 0, backgroundColor: batteryStyles(row), color: 'white', textAlign: 'center', fontSize: 'small' }}>{battery_level}%</Avatar> : <BatteryUnknownIcon style={{ color: 'white', backgroundColor: 'rgba(243, 59, 43, 1)' }} />}</Link> 
              } else if (renderType === 'group') {
                battery_level = row
                // if not error, just return the value
                return battery_level >= '0' ? battery_level : <BatteryUnknownIcon style={{ color: 'gray'}} />
              } 
            },
          },
          {
            title: t('volume_level'),
            field: 'volume_level',
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'volume_level') || -1,
            editComponent: () => <></>,
            render: (row, renderType) => { 
              var volume_level = '-1'
              if (renderType === 'row') {
                volume_level = row.volume_level
                return <Link to={'/sensors'}>{volume_level >= '0' ? <Avatar style={{ margin: 0, backgroundColor: levelStyles(row), color: 'white', textAlign: 'center', fontSize: 'small' }}>{volume_level}%</Avatar> : <SignalCellularConnectedNoInternet0BarIcon style={{ color: 'white', backgroundColor: 'rgba(243, 59, 43, 1)' }} />}</Link> 
              } else if (renderType === 'group') {
                volume_level = row
                // if not error, just return the value
                return  volume_level >= '0' ? volume_level : <SignalCellularConnectedNoInternet0BarIcon style={{ color: 'gray' }} />

              }
            },
          }
        ]
        )
      })
  }

  const sortByProp = (arr = [], prop, direction, parent) => {
    if (
      !arr.length ||
      !prop ||
      !["asc", "desc"].includes(direction.toLowerCase())
    ) return;

    let sort
    if (!parent) {
      sort = (x, y) => (x[prop] < y[prop] ? 1 : -1)
      if (direction === "asc") sort = (x, y) => (x[prop] > y[prop] ? 1 : -1)
    }
    else {
      sort = (x, y) => (x[parent][prop] < y[parent][prop] ? 1 : -1)
      if (direction === "asc") sort = (x, y) => (x[parent][prop] > y[parent][prop] ? 1 : -1)
    }

    return arr.sort(sort);
  };

  useEffect(() => {
    fetchData();
  }, [i18n.lang]);

  return (<>
    { hasError && <Error hasError={hasError} setErrors={() => setErrors()} />}
    <MaterialTable
      title={t("bin_locations")}
      onGroupRemoved={(group) => {
        sessionStorage.removeItem(grouppage + group.field)
      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} of {count}',
          labelRowsSelect: t("labelRowsSelect"),
          labelRowsPerPage: t("labelRowsPerPage"),
          firstAriaLabel: t("firstAriaLabel"),
          firstTooltip: t("firstTooltip"),
          previousAriaLabel: t("previousAriaLabel"),
          previousTooltip: t("previousTooltip"),
          nextAriaLabel: t("nextAriaLabel"),
          nextTooltip: t("nextTooltip"),
          lastAriaLabel: t("lastAriaLabel"),
          lastTooltip: t("lastTooltip"),
        },
        toolbar: {
          nRowsSelected: '{0} row(s) selected',
          searchTooltip: t("search"),
          searchPlaceholder: t("search"),
          exportTitle: t("export"),
        },
        header: {
          actions: t("action")
        },
        grouping: {
          placeholder: t('groupingPlaceholder'),
        },
        body: {
          emptyDataSourceMessage: t("no_records"),
          filterRow: {
            filterTooltip: t("filter")
          },
          editRow: {
            saveTooltip: t("save"),
            cancelTooltip: t("cancel"),
            deleteText: t("delete_text")
          },
          addTooltip: t("add"),
          deleteTooltip: t("delete"),
          editTooltip: t("edit")
        }
      }}
      columns={columns}
      data={JSON.parse(JSON.stringify(data))}
      options={{
        sorting: true,
        grouping: true,
        paging: true,
        pageSize: 10,
        pageSizeOptions: ([10, 25, 50]),
        // toolbar: false,
        rowStyle: row => ({
          backgroundColor: rowStyles(row), color: 'white'
        }),
        cellStyle: ({ padding: 2 }),
        headerStyle: ({ padding: 6 }),
        addRowPosition: 'first',
        thirdSortClick: false,
      }}
      components={{
        GroupRow: props => (GroupRow(props)),
        Groupbar: props => (GroupByBar(props, grouppage))
      }}
    />
  </>)
}
