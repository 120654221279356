import { url, headers } from './url';

export async function countries() {
  return await fetch(url + 'country', {
    method: 'GET',
    headers: headers()
  });
};

export async function bin_type() {
  return await fetch(url + 'bin_type', {
    method: 'GET',
    headers: headers()
  });
};

export async function waste_type() {
  return await fetch(url + 'waste_stream', {
    method: 'GET',
    headers: headers()
  });
};
