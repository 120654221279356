 export function nl() {
  return {
    "The type must be a string.": "Filter soort veld verplicht",
    "Location with this name and level already exists.": "Er bestaat al een locatie met deze naam en verdieping",
    "date": "Datum",
    "resolved": "Opgelost",
    "edit_profile": "Bewerk profiel",
    "complete_profile": "Voltooi uw profiel",
    "username": "Gebruikersnaam",
    "role": "Rol",
    "update_profile": "Profiel aanpassen",
    "en": "Engels",
    "nl": "Nederlands",
    "fr": "Frans",
    "error": "Fout",
    "warning": "Waarschuwing",
    "info": "Informatie",
    "log": "Log",
    "all": " - ",
    "unresolved": "Onopgelost",
    "bin_volume": "Bakvolume",
    "battery_level": "Batterijniveau",
    "connection_status_ok": "online",
    "connection_status_error": "offline",
    "connection_status_": "geen sensor",
    "sensor_status": "Sensor status",
    "sensor_offline": "Sensor offline",
    "sensor_online": "Sensor online",
    "sensor_removed": "Sensor verwijderd",
    "sensor_added": "Sensor toegevoegd",
    "replace_battery": "Vervang de batterij",
    "empty_bin_timed": "Bak legen, tijd is verstreken",
    "empty_bin_full": "Bak legen, bak is vol",
    "check_sensor": "Controleer sensor",
    "bin_message_type": "Melding",
    "sensor_communication": "Sensor communicatie",
    "customer": "Klant",
    "customers": "Klanten",
    "country": "Land",
    "language": "Taal",
    "project": "Project",
    "projects": "Projecten",
    "adres": "Adres",
    "postal_code": "Postcode",
    "city": "Stad",
    "favorite": "Favoriet",
    "favorites": "Favoriete",
    "sign_out": "Uitloggen",
    "settings": "Instellingen",
    "mutation_logs": "Mutatielogboek",
    "access_control": "Toegangscontrole",
    "reports": "Rapportages",
    "notification": "Notificaties",
    "work_in_progress": "Werk in uitvoering",
    "container": "Container",
    "containers": "Containers",
    "bin_locations": "Containerlocaties",
    "bin_location": "Containerlocatie",
    "resource": "Bron",
    "created": "Aangemaakt",
    "deleted": "Verwijderd",
    "updated": "Gewijzigd",
    "data": "Gegevens",
    "location": "Locatie",
    "locations": "Locaties",
    "home": "Startpagina",
    "type": "Soort",
    "container_locaties_project": "Containerlocaties project",
    "sensor": "Sensor",
    "sensors": "Sensoren",
    "protocol_type": "Protocol",
    "calibration_settings_int": "Kalibratie-instellingen int",
    "calibration_settings_float": "Kalibratie-instellingen float",
    "calibration_settings_string": "Kalibratie-instellingen string",
    "interval_settings_int": "interval_settings_int",
    "interval_settings_float": "interval_settings_float",
    "interval_settings_string": "interval_settings_string",
    "interval_settings_data_interval": "Data interval",
    "level": "Verdieping",
    "bin_type": "Containersoort",
    "bin_types": "Containersoorten",
    "waste_stream": "Afvalsoort",
    "volume_level": "Vulgraad",
    "attention": "Let op!",
    "are_you_sure": "Ja, ik weet het zeker",
    "delete_sensor_1": "Weet je zeker dat je de sensor",
    "delete_sensor_2": "wilt ontkoppelen?",
    "delete_plan": "Weet je dat zeker dat je deze plattegrond wilt verwijderen?",
    "delete_text": "Weet je dat zeker dat je deze rij wilt verwijderen?",
    "delete_marker": "Weet je dat zeker dat je deze container wilt verwijderen?",
    "cancel_dialog": "Annuleren",
    "cancel": "Annuleren (ctrl+x)",
    "save": "Opslaan (ctrl+shift+s)",
    "save_settings": "Opslaan",
    "add": "Toevoegen (ctrl+q)",
    "delete": "Verwijderen",
    "edit": "Wijzigen",
    "no_records": "Geen data om weer te geven",
    "action": "Actie",
    "mutation_action": "Actie",
    "search": "Zoeken",
    "export": "Exporteren",
    "labelRowsSelect": "rijen",
    "labelRowsPerPage": "Rijen per pagina:",
    "firstAriaLabel": "Eerste pagina",
    "firstTooltip": "Eerste pagina",
    "previousAriaLabel": "Vorige pagina",
    "previousTooltip": "Vorige pagina",
    "nextAriaLabel": "Volgende pagina",
    "nextTooltip": "Volgende pagina",
    "lastAriaLabel": "Laatste pagina",
    "lastTooltip": "Laatste pagina",
    "groupingPlaceholder": "Versleep kolommen hier om te per groep te sorteren",
    "sensor_id": "Sensor",
    "sensor_type": "Sensortype",
    "protocol": "Protocol",
    "status": "Status",
    "connection": "Connectie",
    "connection_quality": "Connectie kwaliteit",
    "sensor_state_connected": "Verbonden",
    "sensor_state_disconnected": "Niet verbonden",
    "connection_error": "Verbindingsfout, API server niet beschikbaar",
    "last_seen": "Laatst gezien",
    "last_downlink": "Laatste downlink verzonden",
    "location_level": "Locatie op de verdieping",
    "from": "Periode van",
    "to": "Periode tot",
    "sensor_location": "Sensorlocatie",
    "unlink": "Ontkoppel sensor",
    "no_sensors": "Geen sensoren",
    "no_sensor": "Geen sensor",
    "link_sensor": "Koppel sensor",
    "email": "E-mailadres",
    "password": "Wachtwoord",
    "remember": "Onthoud mij",
    "login": "Aanmelden",
    "sign_in": "Inloggen",
    "floorplan": "Containerlocaties op een locatie",
    "The given data was invalid.": "De ingevoerde gegevens kloppen niet.",
    "The location id field is required.": "Het locatieveld is verplicht.",
    "The waste stream id field is required.": "Het afvalstroom-veld is verplicht.",
    "The name field is required.": "Het naam veld is verplicht.",
    "The address field is required.": "Het adres veld is verplicht.",
    "The bin type id field is required.": "Het containersoort-veld is verplicht.",
    "The postal code field is required.": "Het postcodeveld is verplicht.",
    "The city field is required.": "Het stad-veld is verplicht.",
    "The image field is required.": "Het afbeeldingsveld is verplicht.",
    "The country id field is required.": "Het is verplicht een land te selecteren.",
    "The name has already been taken.": "Naam is al in gebruik.",
    "The bin location id has already been taken.": "Containerlocatie bestaat al op deze plattegrond",
    "The x field is required.": "Geen x-positie geselecteerd.",
    "The y field is required.": "Geen y-positie geselecteerd.",
    "Floorplan with identifier(s) undefined not found.": "Geen plattegrond gevonden.",
    "The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.":
    "De verstrekte autorisatietoekenning (bijv. autorisatiecode, referenties van broneigenaar) of vernieuwingstoken is ongeldig, verlopen, ingetrokken, komt niet overeen met de omleidings-URI die is gebruikt in het autorisatieverzoek of is uitgegeven aan een andere klant.",
    "No support for protocol lora": "LoRa-protocol wordt nog niet ondersteund.",
    "Client authentication failed":"Autenticatie mislukt.",
    "default": "Standaard",
    "custom": "Aangepast",
    "user": "Gebruiker",
    "users": "Gebruikers",
    "admin": "Admin",
    "notification_field": "Beschrijving",
    "urgency": "Urgentie-niveau",
    "notification_type": "Soort",
    "creation_date": "Berichtdatum",
    "battery_error": "Batterijfout",
    "battery_warning": "Batterij-waarschuwing",
    "time_error": "Tijdfout",
    "time_warning": "Tijd-waarschuwing",
    "volume_error": "Volumefout",
    "volume_warning": "Volume-waarschuwing",
    "The roles field is required.": "Het rol-veld is verplicht.",
    "The email field is required.": "Het e-mailveld is verplicht.",
    "The password field is required.": "Het wachtwoordveld is verplicht.",
    "The email has already been taken.": "E-mailadres is al in gebruik.",

    "hour": "uur",
    "minutes": "minuten",

    "bin_location_setting": "Containerlocatie-instelling",
    "default_bin_location_setting": "Globale containerlocatie-instelling",

    "global_bin_location_settings": "Containerlocatie-instellingen",
    "change_global_bin_location_settings": "Globale instellingen voor containerlocaties instellen",

    "waste_streams": "Afvalstromen",

    "Unable to remove the waste stream because the waste stream still has active bin location(s)" : "Kan afvalstroom niet verwijderen. Afvalstroom wordt gebruikt in actieve containerlocaties",

    "max_volume_cm": "Gemeten volume wanneer leeg (cm)",
    "min_volume_cm": "Gemeten volume wanneer vol (cm)",
    "floorplan_bin_marker": "Plattegrond & Pins",
    "bin_location_sensor": "Containerlocatie sensor",
    "user-roles": "Gebruikersrollen",

    "The postal code may only contain letters and numbers." : "De postcode mag alleen letters en cijfers bevatten."
  }
}
