import React, { useState, useEffect } from "react";
import MaterialTable from 'material-table';
import GroupByBar from './group_by_bar';
import GroupRow from './group_row';
import { mutations } from '../api/mutations';
import { errorMessage } from '../utils/error_message';
import { formatDate } from '../utils/date';
import Error from '../components/error.js';
import { useTranslation } from 'react-i18next';

export default function Mutations() {
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumn] = useState([]);
  const grouppage = 'mutations.'

  async function fetchData() {
    const res = await mutations({})
    res
      .json()
      .then(res => {
        setColumn([
          {
            title: t('username'), field: 'executed_by.name',
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'executed_by.name') || -1,
          },
          {
            title: t('mutation_action'), render: (r) => t(r.change_event), field: 'change_event',
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'change_event') || -1,
            render: (row, renderType) => customRender(row, renderType, renderCellData, 'change_event')
          },
          {
            title: t('resource'),
            field: 'target_type',
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'target_type') || -1,
            render: (r) => t(r.target_type) || t(r)
          },
          {
            title: t('data'),
            field: 'target.label',
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'target.label') || -1,
            render: (r) => (r.target && t(r.target.label)) || r.length > 0 && t(r)
          },
          {
            title: t('date'),
            field: 'date',
            customSort: (a, b, renderType) => {
              if (renderType === 'group') {
                if (!a) return -1;
                if (!b) return 1;
                var x = (a).replace(/\D+/g, '');
                var y = (b).replace(/\D+/g, '');
                return x < y ? -1 : x > y ? 1 : 0;
              }
              if (renderType === 'group') {
                if (!a.date) return -1;
                if (!b.date) return 1;
                var x = a.date.replace(/\D+/g, '');
                var y = b.date.replace(/\D+/g, '');
                return x < y ? -1 : x > y ? 1 : 0;
              }
            },
            render: (row, renderType) => {
              var date = customRender(row, renderType, renderCellData, 'date')
              return formatDate(date)
            },
            defaultGroupOrder: sessionStorage.getItem(grouppage + 'date') || -1,
          },
        ])
        if (res.error) return errorMessage(res, setErrors)
        setData(res.data)
      })
  }


  function customRender(value, renderType, renderFunc, field) {
    if (renderType === 'row') {
      return value[field]
    }
    if (renderType === 'group') {
      return value
    }
  }
  function renderCellData(column) {
    sessionStorage.setItem(column, 1)
  }

  useEffect(() => {
    fetchData()
  }, [i18n.lang])

  return (<>
    { hasError && <Error hasError={hasError} setErrors={() => setErrors()} />}
    <MaterialTable
      title={t('mutation_logs')}
      onGroupRemoved={(group) => {
        sessionStorage.removeItem(grouppage + group.field)
      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} of {count}',
          labelRowsSelect: t("labelRowsSelect"),
          labelRowsPerPage: t("labelRowsPerPage"),
          firstAriaLabel: t("firstAriaLabel"),
          firstTooltip: t("firstTooltip"),
          previousAriaLabel: t("previousAriaLabel"),
          previousTooltip: t("previousTooltip"),
          nextAriaLabel: t("nextAriaLabel"),
          nextTooltip: t("nextTooltip"),
          lastAriaLabel: t("lastAriaLabel"),
          lastTooltip: t("lastTooltip"),
        },
        toolbar: {
          nRowsSelected: '{0} row(s) selected',
          searchTooltip: t("search"),
          searchPlaceholder: t("search"),
          exportTitle: t("export"),
        },
        header: {
          actions: t("action")
        },
        grouping: {
          placeholder: t('groupingPlaceholder'),
        },
        body: {
          emptyDataSourceMessage: t("no_records"),
          filterRow: {
            filterTooltip: t("filter")
          },
          editRow: {
            saveTooltip: t("save"),
            cancelTooltip: t("cancel"),
            deleteText: t("delete_text")
          },
          addTooltip: t("add"),
          deleteTooltip: t("delete"),
          editTooltip: t("edit")
        }
      }}
      columns={columns}
      data={data}
      options={{
        grouping: true,
        paging: true,
        pageSize: 10,
        pageSizeOptions: ([10, 25, 50]),
        cellStyle: ({ padding: 2 }),
        headerStyle: ({ padding: 6 }),
        exportButton: true,
        exportAllData: true,
      }}
      components={{
        GroupRow: props => (new GroupRow(props)),
        Groupbar: props => (GroupByBar(props, grouppage))
      }}
    />
  </>)
}
