import React, { useState, useEffect } from "react";
import MaterialTable, { MTableBody, MTableFilterRow } from 'material-table';
import { sensors_report } from '../api/sensors';
import { errorMessage } from '../utils/error_message';
import { formatDate } from '../utils/date';
import Error from '../components/error.js';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Report() {
  const [selectedDate, setSelectedDate] = useState('');
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);

  async function fetchData() {
    const res = await sensors_report()
    res.json()
      .then(res => {
        if (res.error) return errorMessage(res, setErrors)
        setData(res.data)
      })
  }

  useEffect(() => {
    fetchData();
  }, [i18n.lang])

  return (<>
    { hasError && <Error hasError={ hasError } setErrors={ () => setErrors() } />}
    <MaterialTable
      title={t('reports')}
      components={{
       // FilterRow: props => <MTableFilterRow {...props} onFilterChanged={(columnId, value) => {
       //   props.onFilterChanged(columnId, value);
       // }}/>,
     }}
      localization={{
        pagination: {
              labelDisplayedRows: '{from}-{to} of {count}',
              labelRowsSelect: t("labelRowsSelect"),
              labelRowsPerPage: t("labelRowsPerPage"),
              firstAriaLabel: t("firstAriaLabel"),
              firstTooltip: t("firstTooltip"),
              previousAriaLabel: t("previousAriaLabel"),
              previousTooltip: t("previousTooltip"),
              nextAriaLabel: t("nextAriaLabel"),
              nextTooltip: t("nextTooltip"),
              lastAriaLabel: t("lastAriaLabel"),
              lastTooltip: t("lastTooltip"),
          },
          toolbar: {
              nRowsSelected: '{0} row(s) selected',
              searchTooltip: t("search"),
              searchPlaceholder: t("search"),
              exportTitle: t("export"),
          },
          header: {
              actions: t("action")
          },
          grouping: {
            placeholder: t('groupingPlaceholder'),
          },
       body: {
         emptyDataSourceMessage: t("no_records"),
         filterRow: {
             filterTooltip: t("filter")
         },
         editRow: {
           saveTooltip: t("save"),
           cancelTooltip: t("cancel"),
           deleteText: t("delete_text")
         },
         addTooltip: t("add"),
         deleteTooltip: t("delete"),
         editTooltip: t("edit")
       }
      }}
      options={{
        paging: true,
        exportButton: true,
        exportAllData: true,
        filtering: true,
      }}
      columns={[
        {
          title: t('customer'),
          field: 'customer.name',
          render: (row) => { return typeof row === 'string' ? row :  row && row.customer === null ? '' : row && row.customer && row.customer.id && <Link to={"/customer/" + row.customer.id}>{row.customer.name}</Link>},
        },
        {
          title: t('project'),
          field: 'project.name',
          render: (row) => { return typeof row === 'string' ? row : row && row.project === null ? '' : row && row.project && row.project.id && <Link to={"/customer/project/" + row.project.id}>{row.project.name}</Link>},
        },
        { 
          title: t('location'), field: 'location.name',
          render: row => row && row.id && <Link to={ '/plan/' + (row.location && row.location.id)}>{ (row.location && row.location.name)}</Link> || row
        },
        {title: t('level'), field:'location.level', type:"numeric", filtering: false},
        {title: t('bin_location'), field:'bin_location.name', filtering: false},
        {title: t('bin_type'), field:'bin_type.name', filtering: false},
        {title: t('waste_stream'), field:'waste_stream.name', filtering: false},

        {title: t('sensor_id'), field:'sensor_id', filtering: false},
        {
         title: t('from'),
         customFilterAndSearch: (term, r) => {
          if (!r.last_seen) return false;
          if (term.length > 9)
          {
            var part = term.split('/');
            if (part.length == 3){
              var date = new Date(part[2], part[1]-1, part[0]);
              return (r.last_seen && new Date(r.last_seen).toLocaleDateString('zh-Hans-CN',{ year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\D+/g, '')) > date.toLocaleDateString('zh-Hans-CN', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\D+/g, '')
            }
          }
        }
      },
      {
        title: t('to'),
        field:'last_seen',
        render: (r) => r.last_seen && formatDate(r.last_seen),
        customSort: ( a, b ) => { 
          if (!a.last_seen) return -1;
          if (!b.last_seen) return 1;
          var x = a.last_seen.replace(/\D+/g, '');
          var y = b.last_seen.replace(/\D+/g, '');
          return x < y ? -1 : x > y ? 1 : 0; 
        },
        customFilterAndSearch: (term, r) => {
          if (!r.last_seen) return false;
            if (term.length > 9) {
              var part = term.split('/');
              if (part.length == 3){
                var date = new Date(part[2], part[1]-1, part[0]);
                if (date == "Invalid Date") return false;
                return (r.last_seen && new Date(r.last_seen).toLocaleDateString('zh-Hans-CN',{ year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\D+/g, '')) < date.toLocaleDateString('zh-Hans-CN',  { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\D+/g, '')
              }
            } 
          }
        },
      ]}
      data={data}
    />
  </>)
}
