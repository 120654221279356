import { url, headers } from './url';

export async function projects() {
  return await fetch(url + 'project', {
    method: 'GET',
    headers: headers()
  });
}

export async function project_by_customer(cust_id) {
  return await fetch(url + 'customer/' + cust_id + '/project', {
    method: 'GET',
    headers: headers()
  });
};


// project
export async function project(id) {
  return await fetch(url + 'project/' + id, {
    method: 'GET',
    headers: headers()
  });
};

export async function project_add(data) {
  const res = await fetch(url + 'project', {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function project_update(data) {
  const res = await fetch(url + 'project/' + data.id, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function project_del(id) {
  const res = await fetch(url + 'project/' + id, {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json()
};
