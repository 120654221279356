import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useConfirm } from "material-ui-confirm";
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Check from '@material-ui/icons/Check';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { sensor_id, sensors_unlinked, unlink_sensor, link_sensor, sensor_interval_settings_update } from '../api/sensors';
import { bin_location_settings, bin_location_settings_update } from '../api/bin_location';
import { formatDate } from '../utils/date';
import { useTranslation } from 'react-i18next';
import Error from '../components/error.js';
import { errorMessage } from '../utils/error_message';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from "../components/CustomButtons/Button.js";
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}))

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
    <Typography variant="h6">{children}</Typography>
    {onClose ? (
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
      <CloseIcon />
      </IconButton>
    ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs(props) {
  const [hasError, setErrors] = useState(false);
  const { t } = useTranslation();
  const confirm = useConfirm();
  const data = props.row
  const bin_location_id = data.id
  const [sensor, setSensor] = useState({});
  const [intervalSettingsDirty, setIntervalSettingsDirty] = useState(false);
  const classes = useStyles();
  const [sensors, setSensors] = useState([]);
  const [saved, setSaved] = useState(false);
  const [open, setOpen] = useState(false);
  const [linked, setLinked] = useState(false);
  const [hasConfiguration, setHasConfiguration] = useState(true);
  const [settings, setSettings] = useState({});
  const role_admin = sessionStorage.getItem('role') === 'admin';

  async function fetchData() {
    const res = await sensors_unlinked()
    res.json()
    .then(res => {
      setSensors(res.data)
    })
  }

  const handleLinkSensor = (e, sensor) => {
    sensor = sensor || {}
    sensor.bin_location_id = data.id
    link_sensor(sensor).then((res) => {
      if (res.error) return errorMessage(res, setErrors)
      setSensor(res.data)
      setLinked(true)
      props.update()
    })
  }

  const handleClickOpen = () => {
    fetchData()
    setOpen(true)
    setLinked(false)
    setSensor(data.sensor)
    bin_location_settings(bin_location_id).then((res) => {
      if (res.error) return errorMessage(res, setErrors)
      setSettings(res.data)
    })
    if (!data.sensor) return
    sensor_id(data.sensor).then((res) => {
      if (res.error) return errorMessage(res, setErrors)
      setSensor(res.data)
      setLinked(true)
    })

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUnlinkSensor = () => {
    confirm({ title: t('attention') ,
              description: `${t('delete_sensor_1')} ${data.sensor && data.sensor.sensor_id} ${t('delete_sensor_2')}`,
              confirmationText: t('are_you_sure'),
              cancellationText: t('cancel_dialog'),
      }).then((e) => {
        unlink_sensor(data.id).then((res) => {
          if (res.error) return errorMessage(res, setErrors)
          setLinked(false)
          setSensor({})
          props.update()
        })
      }
    ).catch((e) => {})
  }

  return (
    <>
    { hasError && <Error hasError={ hasError } setErrors={ setErrors } />}
    <Link onClick={handleClickOpen} style={{ fontWeight: 500, color: '#9c27b0', textDecoration: 'none'}}>
    {data.name}
    </Link>
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
    Sensor id: {(sensor && sensor.sensor_id) || t('no_sensor') } - {t('container')}: {data.name}
    </DialogTitle>
    <DialogContent dividers>
    {linked && <>
        <Card>
          <CardContent>
            {sensor.sensor_id}
            <Tooltip title={t("unlink")}>
            <IconButton onClick={handleUnlinkSensor} aria-label={t("unlink")} style={{color:"red"}}>
            <NotInterestedIcon></NotInterestedIcon>
            </IconButton>
            </Tooltip>
          </CardContent>
        </Card>
      </>
    }
    {!linked && <>
      <Autocomplete
      id="combo-box-demo"
      options={sensors}
      onChange={handleLinkSensor}
      getOptionLabel={(option) => (option && option.sensor_id) || {sensor_id: t("no_sensors")}}
      style={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label={t("link_sensor")} variant="outlined" />}
      />
      </>
    }
    <form className={classes.root} noValidate autoComplete="off">
    { linked && <>
        <TextField
          id="last_seen"
          label={t('last_seen')}
          value={sensor.last_seen && formatDate(sensor.last_seen)}
          disabled={true}
        />
        <TextField
          id="last_downlink"
          label={t('last_downlink')}
          value={sensor.last_downlink && formatDate(sensor.last_downlink)}
          disabled={true}
        />
        <TextField id="protocol_type" label={t("protocol_type")} value={sensor.protocol_type} disabled={true} />
        <TextField id="sensor_type" label={t("sensor_type")} value={sensor.sensor_type} disabled={true}  />
        {/* (sensor.interval_settings.data_interval_seconds && sensor.interval_settings.data_interval_seconds / 60) || '' */}
        <TextField 
          id="interval_settings_data_interval" 
          label={
            t("interval_settings_data_interval") 
            + (sensor.interval_settings.data_interval_seconds_min && ", min " + Math.round(sensor.interval_settings.data_interval_seconds_min / 60 | "")) 
            + (sensor.interval_settings.data_interval_seconds_max && ", max " + Math.round(sensor.interval_settings.data_interval_seconds_max / 60 | "")) 
          }
          value={sensor.interval_settings.data_interval_seconds && Math.round(sensor.interval_settings.data_interval_seconds / 60) || ''}
          disabled={!role_admin}
          onChange={ (e) => {
              let seconds = e.target.value * 60
              if(e.target.value == sensor.interval_settings.data_interval_seconds) {
                return false;
              }
              if(sensor.interval_settings.data_interval_seconds_min && sensor.interval_settings.data_interval_seconds_min > seconds) {
                return false;
              }
              if(sensor.interval_settings.data_interval_seconds_max && sensor.interval_settings.data_interval_seconds_max < seconds) {
                return false;
              }
              let newEdit = { ...sensor };
              // to seconds
              newEdit.interval_settings.data_interval_seconds = seconds;
              setSensor(newEdit)
              setIntervalSettingsDirty(true)
            }
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">{t('minutes')}</InputAdornment>,
            type: "number",
            step: "1",
          }}
        />
      </>
    }

        { hasConfiguration && role_admin &&
          <>
            <h4> {t('settings')} </h4>
            <TextField id="volume_warning" label={t("volume_warning")} value={(settings && settings.volume_warning && settings.volume_warning.value) || ''}
              disabled={settings && settings.volume_warning && settings.volume_warning.is_default ? true : false}
              onChange={ (e) => {
                  if(e.target.value === '' || RegExp('^[1-9][0-9]?$|^100$').test(e.target.value)) {
                    let newEdit = { ...settings };
                    newEdit.volume_warning.value = e.target.value
                    setSettings(newEdit)
                  }
                }
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                type: "number"
              }}
            />
            <FormControlLabel
              control={<Switch checked={settings && settings.volume_warning && settings.volume_warning.is_default ? true : false} onChange={ (e) => {
                let newEdit = { ...settings };
                newEdit.volume_warning.is_default = e.target.checked
                setSettings(newEdit)
                }
              } />}
              label={settings && settings.volume_warning && settings.volume_warning.is_default ? t('default') : t('custom')}
              labelPlacement="end"
            />
            <TextField id="volume_error" label={t("volume_error")} value={(settings && settings.volume_error && settings.volume_error.value) || ''}
              disabled={settings && settings.volume_error && settings.volume_error.is_default ? true : false}
              onChange={ (e) => {
                  if(e.target.value === '' || RegExp('^[1-9][0-9]?$|^100$').test(e.target.value)) {
                    let newEdit = { ...settings };
                    newEdit.volume_error.value = e.target.value
                    setSettings(newEdit)
                  }
                }
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                type: "number"
              }}
            />
            <FormControlLabel
              control={<Switch checked={settings && settings.volume_error && settings.volume_error.is_default ? true : false} onChange={ (e) => {
                let newEdit = { ...settings };
                newEdit.volume_error.is_default = e.target.checked
                setSettings(newEdit)
                }
              } />}
              label={settings && settings.volume_error && settings.volume_error.is_default ? t('default') : t('custom')}
              labelPlacement="end"
            />
            <TextField id="time_warning" label={t("time_warning")} value={(settings && settings.time_warning && settings.time_warning.value / 3600) || ''}
              disabled={settings && settings.time_warning && settings.time_warning.is_default ? true : false}
              onChange={ (e) => {
                  if(e.target.value === '' || RegExp('^[1-9][0-9]*$').test(e.target.value)) {
                    let newEdit = { ...settings };
                    newEdit.time_warning.value = (e.target.value !== '' ? e.target.value * 3600 : e.target.value);
                    setSettings(newEdit)
                  }
                }
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">{t('hour')}</InputAdornment>,
                type: "number"
              }}
            />
            <FormControlLabel
              control={<Switch checked={settings && settings.time_warning && settings.time_warning.is_default ? true : false} onChange={ (e) => {
                let newEdit = { ...settings };
                newEdit.time_warning.is_default = e.target.checked
                setSettings(newEdit)
                }
              } />}
              label={settings && settings.time_warning && settings.time_warning.is_default ? t('default') : t('custom')}
              labelPlacement="end"
            />
            <TextField id="time_error" label={t("time_error")} value={(settings && settings.time_error && settings.time_error.value / 3600) || ''}
              disabled={settings && settings.time_error && settings.time_error.is_default ? true : false}
              onChange={ (e) => {
                  if(e.target.value === '' || RegExp('^[1-9][0-9]*$').test(e.target.value)) {
                    let newEdit = { ...settings };
                    newEdit.time_error.value = (e.target.value !== '' ? e.target.value * 3600 : e.target.value);
                    setSettings(newEdit)
                  }
                }
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">{t('hour')}</InputAdornment>,
                type: "number"
              }}
            />
            <FormControlLabel
              control={<Switch checked={settings && settings.time_error && settings.time_error.is_default ? true : false} onChange={ (e) => {
                let newEdit = { ...settings };
                newEdit.time_error.is_default = e.target.checked
                setSettings(newEdit)
                }
              } />}
              label={settings && settings.time_error && settings.time_error.is_default ? t('default') : t('custom')}
              labelPlacement="end"
            />
            <TextField id="battery_warning" label={t("battery_warning")} value={(settings && settings.battery_warning && settings.battery_warning.value) || ''}
              disabled={settings && settings.battery_warning && settings.battery_warning.is_default ? true : false}
              onChange={ (e) => {
                  if(e.target.value === '' || RegExp('^[1-9][0-9]?$|^100$').test(e.target.value)) {
                    let newEdit = { ...settings };
                    newEdit.battery_warning.value = e.target.value
                    setSettings(newEdit)
                  }
                }
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                type: "number"
              }}
            />
            <FormControlLabel
              control={<Switch checked={settings && settings.battery_warning && settings.battery_warning.is_default ? true : false} onChange={ (e) => {
                let newEdit = { ...settings };
                newEdit.battery_warning.is_default = e.target.checked
                setSettings(newEdit)
                }
              } />}
              label={settings && settings.battery_warning && settings.battery_warning.is_default ? t('default') : t('custom')}
              labelPlacement="end"
            />
            <TextField id="battery_error" label={t("battery_error")} value={(settings && settings.battery_error && settings.battery_error.value) || ''}
              disabled={settings && settings.battery_error && settings.battery_error.is_default ? true : false}
              onChange={ (e) => {
                  if(e.target.value === '' || RegExp('^[1-9][0-9]?$|^100$').test(e.target.value)) {
                    let newEdit = { ...settings };
                    newEdit.battery_error.value = e.target.value
                    setSettings(newEdit)
                  }
                }
              }
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                type: "number"
              }}
            />
            <FormControlLabel
              control={<Switch checked={settings && settings.battery_error && settings.battery_error.is_default ? true : false} onChange={ (e) => {
                let newEdit = { ...settings }
                newEdit.battery_error.is_default = e.target.checked
                setSettings(newEdit)
                }
              } />}
              label={settings && settings.battery_error && settings.battery_error.is_default ? t('default') : t('custom')}
              labelPlacement="end"
            />
            <Button color="primary" onClick={(event) => {
              let promises = []
              // saving the settings
              setSaved(false)
              let p = bin_location_settings_update(bin_location_id, settings)
              promises.push(p)
              p.then((res) => {
                if (res.error) return errorMessage(res, setErrors)
                setSettings(res.data)
              })

              // saving the interval settings if dirty
              if(intervalSettingsDirty) {
                // TODO create new function
                let p = sensor_interval_settings_update(sensor.id, sensor.interval_settings)
                promises.push(p)
                p.then((res) => {
                  if (res.error) return errorMessage(res, setErrors)
                  setSensor(res.data)
                })
              }

              Promise.all(promises).then((values) => {
                setSaved(true)
                setTimeout(() => { setOpen(false); setSaved(false) }, 1000)
              })
            }} >{t('save_settings')}</Button>
          </>
        }
        { saved && <IconButton style={{maxWidth: 50}} ><Check style={{color: 'green'}}/></IconButton> }
      </form>
      </DialogContent>
      </Dialog>
      </>
    );
  }
