import React, { useState, useEffect  } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { Redirect } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { login } from '../api/login.js';
import Error from '../components/error.js';
import { errorMessage } from '../utils/error_message';
import { remember } from "../utils/remember";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [checked, setChecked] = useState(true);
  const classes = useStyles();

  const fetchData = () => {
    i18n.changeLanguage(localStorage.getItem('language'))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleChange = (e) => {
    setChecked(e.target.checked);
  }

  function handleSubmit(event) {
    event.preventDefault()
    login(event.target.email.value, event.target.password.value).then((res) => {
      if (res.error) return errorMessage(res, setErrors)
      if (res.access_token && res.roles) {
        if (checked) {
          localStorage.setItem('access_token', res.access_token)
          localStorage.setItem('expires_in', res.expires_in)
          localStorage.setItem('role', res.roles[1] || res.roles[0])
        }
        localStorage.setItem('name', res.name)
        localStorage.setItem('language', res.language_code)
        sessionStorage.setItem('access_token', res.access_token)
        sessionStorage.setItem('role', res.roles[1] || res.roles[0])
        document.location.href = '/customers'
      }
    }).catch((e) => {
      errorMessage(e, setErrors)
    })
  }
  if (remember()) return (<Redirect to='home' />)
  return (<>
    { hasError && <Error hasError={ hasError } setErrors={ () => setErrors() } />}
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <LockOutlinedIcon />
        <Typography component="h1" variant="h5">
          {t("sign_in")}
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("email")}
            name="email"
            // autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("password")}
            type="password"
            id="password"
            // autoComplete="current-password"
          />
          <FormControlLabel
            control={
              <Checkbox
                id="remember" color="primary"
                checked={checked}
                onChange={handleChange}
                />}
            label={t("remember")}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t('login')}
          </Button>
        </form>
      </div>
    </Container>
    </>);
}
