import React, { useState, useEffect } from "react";
import GroupByBar from './group_by_bar';
import GroupRow from './group_row';
import MaterialTable, { MTableToolbar } from 'material-table';
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from '@material-ui/icons/Refresh';
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { cellColor } from '../utils/notification_colors';
import { Link } from 'react-router-dom';
import { notifications } from '../api/notifications';
import { errorMessage } from '../utils/error_message';
import Error from '../components/error.js';
import Check from '@material-ui/icons/CheckBox';
import CheckBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { useTranslation } from 'react-i18next';

export default function Notifications() {
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumn] = useState([]);
  const [page, set_page] = useState(1);
  const [notification_type, set_notification_type] = useState("action");
  const [type, set_type] = useState('all');
  const [resolved, set_resolved] = useState(false);
  const [level, set_level] = useState("error");

  const grouppage = 'notification.'
  function customRender(value, renderType, renderFunc, field) {
    if (renderType === 'row') {
      return value[field]
    }
    if (renderType === 'group') {
      return value
    }
  }
  function renderCellData(column) {
    sessionStorage.setItem(column, 1)
  }
  async function fetchData() {
    // page is the limit
    // filters: "",
    // page: page,
    const filters = {
      notification_type: notification_type,
      type: type,
      resolved: resolved,
      level: level,
    }
    const res = await notifications(filters)
    res.json().then((res) => {
      setColumn([
        // { title: t('Id'), field: 'id', filtering: false },
        {
          title: t('customer'),
          field: 'customer.name',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'customer.name') || -1,
          filtering: false,
          render: (row) => { return typeof row === 'string' ? row : row && row.customer === null ? '' : row && row.customer && row.customer.id && <Link to={"/customer/" + row.customer.id}>{row.customer.name}</Link> },
        },
        {
          title: t('project'),
          field: 'project.name',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'project.name') || -1,
          filtering: false,
          render: (row) => { return typeof row === 'string' ? row : row && row.project === null ? '' : row && row.project && row.project.id && <Link to={"/customer/project/" + row.project.id}>{row.project.name}</Link> },
        },
        {
          title: t('bin_location'),
          field: 'bin_location.name',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'bin_location.name') || -1,
          render: row => row && row.id && <Link to={'/plan/' + (row.location && row.location.id)}>{(row.bin_location && row.bin_location.name)}</Link> || row,
          filtering: false,
        },
        {
          title: t('level'), 
          field: 'location.level',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'location.level') || -1,
          customSort: (a, b, renderType) => {
            if (renderType === 'row') {
              if (!a.location || !a.location.level) return -1;
              if (!b.location || !b.location.level) return 1;
              var x = (a.location && a.location.level)-0
              var y = (b.location && b.location.level)-0
              return x < y ? -1 : x > y ? 1 : 0;
            }
            if (renderType === 'group') {
              if ( typeof(a) === 'undefined') return
              if ( typeof(b) === 'undefined') return
              if (!a) return -1
              if (!b) return 1
              var x = a-0
              var y = b-0
              return x < y ? -1 : x > y ? 1 : 0;
            }
          }
        },
        {
          title: t('bin_message_type'),
          field: 'type',
          render: r => t(typeof r === 'string' ? r : r.type),
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'type') || -1,
          tooltip: t('bin_message_type'),
          editComponent: () => <></>,
        },
        {
          title: t('urgency'),
          field: 'level',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'level') || -1,
          render: r => <div style={{ margin: 0, backgroundColor: cellColor(r), color: 'white', textAlign: 'center' }}>{t(typeof r === 'string' ? r : r.level)}</div>,
          editComponent: () => <></>,
        },
        {
          title: t('creation_date'),
          field: 'created_at',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'created_at') || -1,
          customSort: (a, b, renderType) => {
            if (renderType === 'group') {
              if (!a) return -1;
              if (!b) return 1;
              var x = (a).replace(/\D+/g, '');
              var y = (b).replace(/\D+/g, '');
              return x < y ? -1 : x > y ? 1 : 0;
            }
            if (renderType === 'group') {
              if (!a.created_at) return -1;
              if (!b.created_at) return 1;
              var x = a.created_at.replace(/\D+/g, '');
              var y = b.created_at.replace(/\D+/g, '');
              return x < y ? -1 : x > y ? 1 : 0;
            }
          },
          render: (row, renderType) => {
            var date = customRender(row, renderType, renderCellData, 'created_at')
            return new Date(date).toLocaleDateString('en-GB') + ' ' + new Date(date).toLocaleTimeString('it-IT')
          }
        },

        {
          title: t('resolved'), field: 'resolved',
          defaultGroupOrder: sessionStorage.getItem(grouppage + 'resolved') || -1,
          render: r => (typeof r === 'boolean' ? r : r.resolved) ? <Check style={{ fontSize: '1.3rem' }} /> : <CheckBlank style={{ fontSize: '1.3rem' }} />,
        },
      ])
      if (res.error) return errorMessage(res, setErrors)
      setData(res.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [i18n.lang, page, notification_type, type, resolved, level])

  return (<>
    { hasError && <Error hasError={hasError} setErrors={() => setErrors()} />}
    <MaterialTable
      components={{
        Toolbar: props => (
          <>
            <MTableToolbar {...props} />
            <div style={{ padding: 10 }}>
              <FormControl>
                <InputLabel id="select-type">{t('bin_message_type')}</InputLabel>
                <Select
                  id="select-type"
                  value={type}
                  onChange={(e) => { set_type(e.target.value); set_notification_type('all'); set_resolved('all'); }}
                  style={{ marginTop: 13, minWidth: 100 }}
                  formcontrolprops={{
                    fullWidth: false
                  }}
                >
                  <MenuItem value={'all'}>{t('all')}</MenuItem>
                  <MenuItem value={'bin_volume'}>{t('bin_volume')}</MenuItem>
                  <MenuItem value={'battery_level'}>{t('battery_level')}</MenuItem>
                  <MenuItem value={'sensor_offline'}>{t('sensor_offline')}</MenuItem>
                  <MenuItem value={'sensor_online'}>{t('sensor_online')}</MenuItem>
                  <MenuItem value={'sensor_removed'}>{t('sensor_removed')}</MenuItem>
                  <MenuItem value={'sensor_added'}>{t('sensor_added')}</MenuItem>
                  <MenuItem value={'replace_battery'}>{t('replace_battery')}</MenuItem>
                  <MenuItem value={'empty_bin_full'}>{t('empty_bin_full')}</MenuItem>
                  <MenuItem value={'empty_bin_timed'}>{t('empty_bin_timed')}</MenuItem>
                  <MenuItem value={'check_sensor'}>{t('check_sensor')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ marginLeft: 10 }} id="select-level">{t('urgency')}</InputLabel>
                <Select
                  id="select-level"
                  defaultValue={level}
                  onChange={(e) => set_level(e.target.value)}
                  style={{ marginTop: 13, minWidth: 130, marginLeft: 10 }}
                  formcontrolprops={{
                    fullWidth: false
                  }}
                >
                  <MenuItem value={'all'}>{t('all')}</MenuItem>
                  <MenuItem value={'info'}>{t('info')}</MenuItem>
                  <MenuItem value={'warning'}>{t('warning')}</MenuItem>
                  <MenuItem value={'error'}>{t('error')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ marginLeft: 10 }} id="select-notification">{t('notification_type')}</InputLabel>
                <Select
                  id="select-notification"
                  defaultValue={notification_type}
                  onChange={(e) => set_notification_type(e.target.value)}
                  style={{ marginTop: 13, minWidth: 120, marginLeft: 10 }}
                  formcontrolprops={{
                    fullWidth: false
                  }}
                >
                  <MenuItem value={'all'}>{t('all')}</MenuItem>
                  <MenuItem value={'log'}>{t('log')}</MenuItem>
                  <MenuItem value={'action'}>{t('action')}</MenuItem>
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel style={{ marginLeft: 10 }} id="select-resolved">{t('resolved')}</InputLabel>
                <Select
                  id="select-resolved"
                  defaultValue={resolved}
                  onChange={(e) => set_resolved(e.target.value)}
                  style={{ marginTop: 13, minWidth: 100, marginLeft: 10 }}
                  formcontrolprops={{
                    fullWidth: false
                  }}
                >
                  <MenuItem value={true}>{t('resolved')}</MenuItem>
                  <MenuItem value={false}>{t('unresolved')}</MenuItem>
                  <MenuItem value={'all'}>{t('all')}</MenuItem>
                </Select>
              </FormControl>
              <IconButton onClick={() => fetchData()} style={{ float: 'right' }}><RefreshIcon></RefreshIcon></IconButton>
            </div>
          </>
        ),
        Groupbar: props => (GroupByBar(props, grouppage))
      }}
      title={t('notification')}
      onGroupRemoved={(group) => {
        sessionStorage.removeItem(grouppage + group.field)
      }}
      localization={{
        pagination: {
          labelDisplayedRows: '{from}-{to} of {count}',
          labelRowsSelect: t("labelRowsSelect"),
          labelRowsPerPage: t("labelRowsPerPage"),
          firstAriaLabel: t("firstAriaLabel"),
          firstTooltip: t("firstTooltip"),
          previousAriaLabel: t("previousAriaLabel"),
          previousTooltip: t("previousTooltip"),
          nextAriaLabel: t("nextAriaLabel"),
          nextTooltip: t("nextTooltip"),
          lastAriaLabel: t("lastAriaLabel"),
          lastTooltip: t("lastTooltip"),
        },
        toolbar: {
          nRowsSelected: '{0} row(s) selected',
          searchTooltip: t("search"),
          searchPlaceholder: t("search"),
          exportTitle: t("export"),
        },
        header: {
          actions: t("action")
        },
        grouping: {
          placeholder: t('groupingPlaceholder'),
        },
        body: {
          emptyDataSourceMessage: t("no_records"),
          filterRow: {
            filterTooltip: t("filter")
          },
          editRow: {
            saveTooltip: t("save"),
            cancelTooltip: t("cancel"),
            deleteText: t("delete_text")
          },
          addTooltip: t("add"),
          deleteTooltip: t("delete"),
          editTooltip: t("edit")
        }
      }}
      columns={columns}
      data={data}
      // query =>
      // new Promise((resolve, reject) => {
      // await notifications(filters)
      // res.json().then()
      //   let url = 'https://reqres.in/api/users?'
      //   url += 'per_page=' + query.pageSize
      //   url += '&page=' + (query.page + 1)
      //   fetch(url)
      //     .then(response => response.json())
      //     .then(result => {
      //       resolve({
      //         data: result.data,
      //         page: result.page - 1,
      //         totalCount: result.total,
      //       })
      //     })
      // })
      options={{
        grouping: true,
        paging: true,
        pageSize: 50,
        filtering: false,
        pageSizeOptions: ([10, 25, 50]),
        cellStyle: ({ padding: 2 }),
        headerStyle: ({ padding: 6 }),
        exportButton: true,
        exportAllData: true,

      }}
    />
  </>)
}
