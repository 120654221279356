import React, { useState, useEffect } from "react";
import MaterialTable,{ MTableEditRow } from 'material-table';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import Error from '../components/error.js';
import { customers, customer_add, customer_update, customer_del } from '../api/customer';
import { favorite_customers_update } from '../api/user';
import { countries } from '../api/miscellaneous';
import { projects } from '../api/project';
import { errorMessage } from '../utils/error_message';
import { tabindex } from '../utils/tabindex';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import { useTranslation } from 'react-i18next';

const Customers = () => {
  const tableRef = React.createRef();
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumn] = useState([]);
  const [defaultCustomerSort, setDefaultCustomerSort] = useState('asc');
  let [cntrs, setCntrs] = useState({});
  let role_admin = sessionStorage.getItem('role') === 'admin';
  const sortedData = tableRef.current?.dataManager?.sortedData ?? [];

  const getType = (type) => {
    return type ? "#e8eaf5" : ''
  }

  async function fetchData() {
    const [country, customer, project] = await Promise.all([countries(), customers(), projects()])
      const c = await customer.json().then(r => r.data)
      const p = await project.json().then(r => r.data)
      // const sortedData = sortByProp(c && c.concat(p), "name", "desc");
      // setData(sortedData);
      //
      setData(c && c.concat(p))
      setCntrs(await country.json().then(r => {
        cntrs = {}
        r.data && r.data.map(c => {
          const { id, name } = c;
          return cntrs[ id ] = name
        })
      }))
      // orderDirection: 'asc' || 'desc'
      // orderBy: 1 || 2  array count columns

      setColumn([{
        title: t('favorite'),
        field: 'favorite',
        cellStyle: ({padding: 0}),
        editComponent: () => <></>,
        render: (row) => !row.customer_id ? <Tooltip title={t('favorite')}><IconButton tabIndex={-1}
        onClick={() => {
          row.favorite = row.favorite ? false : true
          row.country_id = row.country.id
          setData((prevData) => {
            const data = [...prevData];
            data[data.indexOf(row)] = row;
            return data;
          })
          favorite_customers_update(row).then( res => { if (res.error) return errorMessage(res, setErrors) })
        }}>
        { row.favorite ? <Star  /> : <StarBorderIcon />}
        </IconButton></Tooltip> : ''
      },
      { title: `${t('customer')} ${t('projects')}`,
        field: 'name',
        sorting: true,
        orderDirection: 'desc',
        customSort: ( a, b ) => { return a.name > b.name ? 1 : -1 },
        render: row => <Link to={ '/customer/' + (row.customer_id ? 'project/' : '') + row.id}>{row.name}</Link>,
        editComponent: props => (<div className="MuiFormControl-root MuiTextField-root">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
            <input
              className="MuiInputBase-input MuiInput-input"
              type="text"
              value={props.value || ""}
              onChange={e => props.onChange(e.target.value)}
              autoFocus={true}
            />
          </div>
        </div>)
      },
      {
      title: t('country'),
      field: 'country.id',
      lookup: cntrs,
    },
    { title: t('type'),
    field: 'customer_id',
    sorting: false,
    render: row => (<div style={{ backgroundColor: getType(row.customer_id) }}> { row.customer_id ? t('project') : t('customer') } </div>),
    editComponent: () => <></>,
  }
])
  tabindex()
}

    useEffect(() => {
      fetchData()

    }, [i18n.lang])

const sortByProp = (arr = [], property, ascOrDesc) => {
  if (
    !arr.length ||
    !property ||
    !["asc", "desc"].includes(ascOrDesc.toLowerCase())
  ) return;

  let sortingAlgo = (x, y) => (x[property] < y[property] ? 1 : -1);
  if (ascOrDesc === "asc") {
    sortingAlgo = (x, y) => (x[property] > y[property] ? 1 : -1);
  }

  return arr.sort(sortingAlgo);
};

const editable = {
  isEditable: (row) => !row.customer_id,
  isDeletable: (row) => !row.customer_id,
  onRowAdd: (newData) =>
  new Promise((resolve, reject) => {
    newData.country_id = newData.country && newData.country.id;
    customer_add(newData).then(res => {
      if (res.error) { errorMessage(res, setErrors); reject(); return }
      setData((prevData) => {
        const data = [...prevData]
        if (!newData.customer_id) res.data.type = 'customer'
        data.splice(0, 0, res.data)
        return data
      })
      setErrors()
      resolve()
    })
  }),
  onRowUpdate: (newData, oldData) =>
  new Promise((resolve, reject) => {
    newData.country_id = newData.country.id
    customer_update(newData).then(res => {
      if (res.error) { errorMessage(res, setErrors); reject(); return }
      setData((prevData) => {
        const data = [...prevData];
        data[data.indexOf(oldData)] = res.data;
        return data;
      });
      setErrors()
      resolve()
    })
  }),
  onRowDelete: (oldData) =>
  new Promise((resolve, reject) => {
    customer_del(oldData.id).then(res => {
      if (res.error) { errorMessage(res, setErrors); reject(); return }
      setData(prevData => {
        const data = [...prevData];
        data.splice(data.indexOf(oldData), 1);
        return data
      })
      setErrors()
      resolve()
    })
  })
};

return ( <>
  { hasError && <Error hasError={ hasError } setErrors={ setErrors } />}
  <MaterialTable
  tableRef={tableRef}
  components={{
    EditRow: props => {
      return (
        <MTableEditRow
          {...props}
          onEditingCanceled={(mode, row) => {
            setErrors()
            props.onEditingCanceled(mode);
          }}
        />
      );
    }
  }}
  title={t('customers')}
  columns={columns}
  data={data}
  options={ role_admin ? {
    // paging: false,
    // actionsColumnIndex: -1,
    // grouping: true,
    addRowPosition: 'first',
    thirdSortClick: false,
    cellStyle: ({padding: 0}),
    headerStyle: ({padding: 6}),
  }:{}}
  icons={{
    // Edit: props => <Star {...props} />
  }}
  parentChildData={(row, rows) => rows.find(a => a.id === row.customer_id)}
  localization={{
    pagination: {
      labelDisplayedRows: '{from}-{to} of {count}',
      labelRowsSelect: t("labelRowsSelect"),
      labelRowsPerPage: t("labelRowsPerPage"),
      firstAriaLabel: t("firstAriaLabel"),
      firstTooltip: t("firstTooltip"),
      previousAriaLabel: t("previousAriaLabel"),
      previousTooltip: t("previousTooltip"),
      nextAriaLabel: t("nextAriaLabel"),
      nextTooltip: t("nextTooltip"),
      lastAriaLabel: t("lastAriaLabel"),
      lastTooltip: t("lastTooltip"),
    },
    toolbar: {
      nRowsSelected: '{0} row(s) selected',
      searchTooltip: t("search"),
      searchPlaceholder: t("search"),
      exportTitle: t("export"),
    },
    grouping: {
      placeholder: t('groupingPlaceholder'),
    },
    header: {
      actions: t("action")
    },
    body: {
      emptyDataSourceMessage: t("no_records"),
      filterRow: {
        filterTooltip: t("filter")
      },
      editRow: {
        saveTooltip: t("save"),
        cancelTooltip: t("cancel"),
        deleteText: t("delete_text")
      },
      addTooltip: t("add"),
      deleteTooltip: t("delete"),
      editTooltip: t("edit")
    }
  }}
  editable={role_admin ? editable : {}}
  />
  </>
)


};

export default Customers;
