export function logout() {
  sessionStorage.removeItem('user')
  sessionStorage.removeItem('role')
  sessionStorage.removeItem('access_token')
  localStorage.removeItem('expires_in')
  localStorage.removeItem('user')
  localStorage.removeItem('role')
  localStorage.removeItem('access_token')
  document.location.href = '/login'
}
