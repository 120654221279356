import { url, headers } from './url';

export async function mutations(data) {
  let param = ''
  param+=`per_page=1000&page=1`
  const res = await fetch(url + 'mutation?' + param, {
    method: 'GET',
    headers: headers(),
  });
  return res
};
