import { url, headers } from './url';


export async function bin_location_settings() {
  var res = await fetch(url + 'setting/bin_location', {
    method: 'GET',
    headers: headers()
  })
  return res.json()
};

export async function bin_location_settings_update(data) {
  const res = await fetch(url + 'setting/bin_location', {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json();
};