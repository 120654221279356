import React, { useState, useEffect } from 'react';
import { errorMessage } from '../utils/error_message';
import MaterialTable, { MTableEditRow } from 'material-table';
import { waste_stream, waste_stream_add, waste_stream_update, waste_stream_del } from '../api/waste_stream';
import Error from '../components/error.js';
import { useTranslation } from 'react-i18next';


export default function WasteStreams() {
  const { t, i18n } = useTranslation();
  const role_admin = sessionStorage.getItem('role') === 'admin';
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumn] = useState([]);

  async function fetchData() {
    const res = await waste_stream();
    res
      .json()
      .then(res => {
        let data = res && res.data
        setData(data)
        setColumn([
          {
            title: t('waste_stream'),
            field: 'name',
            render: (row: any) => row && row.name,
            editComponent: props => (<div className="MuiFormControl-root MuiTextField-root">
              <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
                <input
                  className="MuiInputBase-input MuiInput-input"
                  type="text"
                  value={props.value || ""}
                  onChange={e => props.onChange(e.target.value)}
                  autoFocus={true}
                />
              </div>
            </div>),
          }
        ]
       )
     })
  }

  useEffect(() => {
    fetchData();
  }, [i18n.lang]);

  const editable = {
    onRowAdd: (newData) =>
      new Promise((resolve, reject) => {
      waste_stream_add(newData).then(res => {
        if (res.error) { errorMessage(res, setErrors); reject(); return }
        setData((prevState) => {
          const data = [...prevState]
          data.splice(0, 0, res.data);
          return data
        })
        setErrors()
        resolve()
      })
    }),
    onRowUpdate: (newData, oldData) =>
    new Promise((resolve, reject) => {
      if (oldData) {
        waste_stream_update(newData).then(res => {
          if (res.error) { errorMessage(res, setErrors); reject(); return }
          setData((prevState) => {
            const data = [...prevState]
            data[data.indexOf(oldData)] = res.data
            return data
          })
          setErrors()
          resolve()
        })
      }
    }),
    onRowDelete: (oldData) =>
    new Promise((resolve, reject) => {
      waste_stream_del(oldData.id).then(res => {
        if (res.error) { errorMessage(res, setErrors); reject(); return }
        setData((prevState) => {
          const data = [...prevState]
          data.splice(data.indexOf(oldData), 1)
          return data
        })
        setErrors()
        resolve()
      })
    }),
  };

  return (<>
      { hasError && <Error hasError={ hasError } setErrors={ () => setErrors() } />}
      <MaterialTable
        title={t("waste_streams")}
        components={{
          EditRow: props => {
            return (
              <MTableEditRow
              {...props}
              onEditingCanceled={(mode, row) => {
                setErrors()
                props.onEditingCanceled(mode);
              }}
              />
            );
          }
        }}
        localization={{
          pagination: {
                labelDisplayedRows: '{from}-{to} of {count}',
                labelRowsSelect: t("labelRowsSelect"),
                labelRowsPerPage: t("labelRowsPerPage"),
                firstAriaLabel: t("firstAriaLabel"),
                firstTooltip: t("firstTooltip"),
                previousAriaLabel: t("previousAriaLabel"),
                previousTooltip: t("previousTooltip"),
                nextAriaLabel: t("nextAriaLabel"),
                nextTooltip: t("nextTooltip"),
                lastAriaLabel: t("lastAriaLabel"),
                lastTooltip: t("lastTooltip"),
            },
            toolbar: {
                nRowsSelected: '{0} row(s) selected',
                searchTooltip: t("search"),
                searchPlaceholder: t("search"),
                exportTitle: t("export"),
            },
            header: {
                actions: t("action")
            },
            grouping: {
              placeholder: t('groupingPlaceholder'),
            },
         body: {
            emptyDataSourceMessage: t("no_records"),
            filterRow: {
              filterTooltip: t("filter")
            },
            editRow: {
              saveTooltip: t("save"),
              cancelTooltip: t("cancel"),
              deleteText: t("delete_text")
            },
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit")
          }
        }}
        columns={columns}
        data={data}
        options={{
          paging: true,
          pageSize: 10,
          pageSizeOptions:([10, 25, 50]),
          cellStyle: ({padding: 2}),
          headerStyle: ({padding: 6}),
          addRowPosition: 'first',
          thirdSortClick: false,
        }}
        editable={role_admin ? editable : {}}
      />
  </>)
}
