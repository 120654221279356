import { url, headers } from './url';

// sensors
export async function sensors_report() {
  return await fetch(url + 'sensor?detailed', {
    method: 'GET',
    headers: headers()
  });
};

export async function sensors_unlinked() {
  return await fetch(url + 'sensor?unlinked', {
    method: 'GET',
    headers: headers()
  });
};

export async function sensors() {
  return await fetch(url + 'sensor', {
    method: 'GET',
    headers: headers()
  });
};


export async function sensor_id(data) {
  const res = await fetch(url + 'sensor/' + data.id, {
    method: 'GET',
    headers: headers()
  });
  return res.json();
};

export async function sensor_id_detailed(data) {
  const res = await fetch(url + 'sensor/' + data.id + '?detailed', {
    method: 'GET',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json();
};


export async function sensor_add(data) {
  const res = await fetch(url + 'sensor', {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function sensor_update(data) {
  const res = await fetch(url + 'sensor/' + data.id, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json();
};

export async function sensor_del(id) {
  const res = await fetch(url + 'sensor/' + id, {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json()
};

export async function sensor_interval_settings_update(id, data) {
  const res = await fetch(url + 'sensor/' + id + '/interval_settings', {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

// /bin_location/{id}/sensor
export async function unlink_sensor(id) {
  const res = await fetch(url + 'bin_location/' + id + '/sensor', {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json()
};

export async function link_sensor(data) {
  const res = await fetch(url + 'bin_location/' + data.bin_location_id + '/sensor', {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};
