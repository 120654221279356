import { url, headers } from './url';

// container Locations
export async function bin_location() {
  return await fetch(url + 'bin_location', {
    method: 'GET',
    headers: headers()
  });
};

export async function bin_location_detailed() {
  return await fetch(url + 'bin_location?detailed', {
    method: 'GET',
    headers: headers()
  });
};

export async function bin_location_settings(id) {
  var res = await fetch(url + 'bin_location/'+ id + '/setting', {
    method: 'GET',
    headers: headers()
  })
  return res.json()
};

export async function bin_location_settings_update(id, data) {
  const res = await fetch(url + 'bin_location/' + id + '/setting', {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json();
};

export async function project_bin_locations(id) {
  return await fetch(url + 'project/' + id + '/bin_location', {
    method: 'GET',
    headers: headers()
  })
};

export async function bin_locations_by_location(id) {
  return await fetch(url + 'location/' + id + '/bin_location', {
    method: 'GET',
    headers: headers()
  });
};

export async function bin_location_add(data) {
  const res = await fetch(url + 'bin_location', {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function bin_location_update(data) {
  const res = await fetch(url + 'bin_location/' + data.id, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json();
};

export async function bin_location_del(id) {
  const res = await fetch(url + 'bin_location/' + id, {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json()
};
