import React from "react";
import UserProfile from './profile.js';
import GlobalBinLocationSettings from './global_bin_location_settings.js';
import WasteStreams from './waste_streams.js';
import BinTypes from './bin_types.js';

export default function Settings() {
  const role_admin = sessionStorage.getItem('role') === 'admin';

  return (<>
    <UserProfile />
    { role_admin && 
      <>
        <GlobalBinLocationSettings />
        <WasteStreams />
        <br/>
        <BinTypes /> 
      </>
    }
  </>)
}
