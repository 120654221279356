import React, { useState, useEffect } from "react";
import MaterialTable,{ MTableEditRow } from 'material-table';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import Error from '../components/error.js';
import { users, users_add, users_update, users_del } from '../api/access_control';
import { errorMessage } from '../utils/error_message';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';
import { useTranslation } from 'react-i18next';
import { tabindex } from '../utils/tabindex';

const Access = () => {
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([{'user':'test', 'email':'test@mail.org', 'role': 0}]);
  const [columns, setColumn] = useState([]);
  let role_admin = sessionStorage.getItem('role') === 'admin';

  const getType = (type) => {
    return type ? "#e8eaf5" : ''
  }

  async function fetchData() {
    let use = await users();
      use
      .json()
      .then(r => {
        if (r.error) { errorMessage(r, setErrors); return }
        setData(r.data)
      })
    setColumn([
      { title: t('user'), field: 'name',
        editComponent: props => (<div className="MuiFormControl-root MuiTextField-root">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
            <input
              className="MuiInputBase-input MuiInput-input"
              type="text"
              value={props.value || ""}
              onChange={e => props.onChange(e.target.value)}
              autoFocus={true}
            />
          </div>
        </div>)},
      { title: t('email'), field: 'email',},
      { title: t('role'), field: 'roles[0]', lookup: {'user': t('user'), 'admin': t('admin')}, render: (row) => t(row.roles && row.roles[0]) },
      { title: t('language'), field: 'language_code', render: (row) => t(row.language_code), editComponent: () => <></>,},
      { title: t('password'), field: 'password',
      editComponent: props => (
        <div className="MuiFormControl-root MuiTextField-root">
          <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl">
            <input
              className="MuiInputBase-input MuiInput-input"
              type="password"
              value={props.value || ""}
              onChange={e => props.onChange(e.target.value)}
            />
          </div>
        </div>)},
    ])
    tabindex()
  }

  useEffect(() => {
    fetchData()
  }, [i18n.lang])

const editable = {
  onRowAdd: (newData) =>
  new Promise((resolve, reject) => {
    users_add(newData).then(res => {
      if (res.error) { errorMessage(res, setErrors); reject(); return }
      setData((prevData) => {
        const data = [...prevData]
        data.splice(0, 0, res.data)
        return data
      })
      setErrors()
      resolve()
    })
  }),
  onRowUpdate: (newData, oldData) =>
  new Promise((resolve, reject) => {
    users_update(newData).then(res => {
      if (res.error) { errorMessage(res, setErrors); reject(); return }
      setData((prevData) => {
        const data = [...prevData];
        data[data.indexOf(oldData)] = res.data;
        return data;
      });
      setErrors()
      resolve()
    })
  }),
  onRowDelete: (oldData) =>
  new Promise((resolve, reject) => {
    users_del(oldData.id).then(res => {
      if (res.error) { errorMessage(res, setErrors); reject(); return }
      setData(prevData => {
        const data = [...prevData];
        data.splice(data.indexOf(oldData), 1);
        return data
      })
      setErrors()
      resolve()
    })
  })
};

return ( <>
  { hasError && <Error hasError={ hasError } setErrors={ setErrors } />}
  <MaterialTable
  components={{
    EditRow: props => {
      return (
        <MTableEditRow
          {...props}
          onEditingCanceled={(mode, row) => {
            setErrors()
            props.onEditingCanceled(mode);
          }}
        />
      );
    }
  }}
  title={t('users')}
  columns={columns}
  data={data}
  options={ role_admin ? {
    // paging: false,
    // actionsColumnIndex: -1,
    // grouping: true,
    cellStyle: ({padding: 0}),
    headerStyle: ({padding: 6}),
    addRowPosition: 'first',
    thirdSortClick: false,
  }:{}}
  icons={{
    // Edit: props => <Star {...props} />
  }}
  localization={{
    pagination: {
      labelDisplayedRows: '{from}-{to} of {count}',
      labelRowsSelect: t("labelRowsSelect"),
      labelRowsPerPage: t("labelRowsPerPage"),
      firstAriaLabel: t("firstAriaLabel"),
      firstTooltip: t("firstTooltip"),
      previousAriaLabel: t("previousAriaLabel"),
      previousTooltip: t("previousTooltip"),
      nextAriaLabel: t("nextAriaLabel"),
      nextTooltip: t("nextTooltip"),
      lastAriaLabel: t("lastAriaLabel"),
      lastTooltip: t("lastTooltip"),
    },
    toolbar: {
      nRowsSelected: '{0} row(s) selected',
      searchTooltip: t("search"),
      searchPlaceholder: t("search"),
      exportTitle: t("export"),
    },
    grouping: {
      placeholder: t('groupingPlaceholder'),
    },
    header: {
      actions: t("action")
    },
    body: {
      emptyDataSourceMessage: t("no_records"),
      filterRow: {
        filterTooltip: t("filter")
      },
      editRow: {
        saveTooltip: t("save"),
        cancelTooltip: t("cancel"),
        deleteText: t("delete_text")
      },
      addTooltip: t("add"),
      deleteTooltip: t("delete"),
      editTooltip: t("edit")
    }
  }}
  editable={role_admin ? editable : {}}
  />
  </>
)


};

export default Access;
