export function tabindex() {
  var x = document.getElementsByTagName("th")
  for (var i = 0; i < x.length; i++) {
    var y = x[i].getElementsByTagName("span")[0]
    var z = x[i].getElementsByTagName("div")[0]
    if (y) {
      y.tabIndex = -1
    }
    if (z) {
      z.tabIndex = -1
    }
  }
  document.getElementsByTagName("input")[0].focus()
}
