import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import CustomInput from "../components/CustomInput/CustomInput.js";
import IconButton from '@material-ui/core/IconButton';
import Check from '@material-ui/icons/Check';
import Button from "../components/CustomButtons/Button.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardBody from "../components/Card/CardBody.js";
import CardFooter from "../components/Card/CardFooter.js";
import Error from '../components/error.js';
import { useTranslation } from 'react-i18next';
import { language_api, user, user_update } from '../api/user';
import { errorMessage } from '../utils/error_message';

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const { t, i18n } = useTranslation();
  const [saved, setSaved] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [language, setLanguage] = React.useState(localStorage.getItem('language'));
  const [current, setCurrentUser] = React.useState({});

  const fetchData = () => {
    language_api().then(res => {
      if (res.error) return errorMessage(res, setErrors)
    })
    user().then(res => {
      if (res.error) return errorMessage(res, setErrors)
      setCurrentUser(res.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const editProfile = (event) => {
    user_update({ name: current.name, language_code:language }).then((res) => {
      if (res.error) return errorMessage(res, setErrors)
      setSaved(true)
      setTimeout(() => setSaved(false), 3000)
      setLanguage(res.data.language_code)
      i18n.changeLanguage(res.data.language_code)
      localStorage.setItem('language', res.data.language_code)
    })
  }

  const classes = useStyles();
  return (
    <div>
      { hasError && <Error hasError={ hasError } setErrors={ setErrors } />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>{t('edit_profile')}</h4>
              <p className={classes.cardCategoryWhite}>{t('complete_profile')}</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText={t('username')}
                    id="username"
                    formcontrolprops={{
                      fullWidth: true
                    }}
                    inputProps={{ value: current.name || '', onChange:(e) => {
                      let newEdit = { ...current };
                      newEdit.name = e.target.value;
                      setCurrentUser(newEdit)
                      } }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText={t('email')}
                    id="email"
                    formcontrolprops={{
                      fullWidth: true
                    }}
                    inputProps={{ value: current.email || '', onChange:(e) => {
                      let newEdit = { ...current };
                      newEdit.email = e.target.value;
                      setCurrentUser(newEdit)
                    }, disabled:true
                    }}
                  />
                  </GridItem>
                </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    inputProps={{ value: current.roles && current.roles[0] || '', disabled: true}}
                    labelText={t('role')}
                    id="role"
                    formcontrolprops={{
                      fullWidth: true
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                   <Select
                     id="select"
                     defaultValue={language}
                     onChange={(e) => setLanguage(e.target.value)}
                     style={{ marginTop:43, minWidth:100 }}
                     formcontrolprops={{
                       fullWidth: true
                     }}
                   >
                   <MenuItem value={'nl'}>{t('nl')}</MenuItem>
                   <MenuItem value={'fr'}>{t('fr')}</MenuItem>
                   <MenuItem value={'en'}>{t('en')}</MenuItem>
                 </Select>
                </GridItem>
              </GridContainer>

            </CardBody>
            <CardFooter>
              <Button color="primary" onClick={(event) => editProfile(event)} >{t('update_profile')}</Button>
              { saved && <IconButton style={{maxWidth: 50}} ><Check style={{color: 'green'}}/></IconButton> }
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>

        </GridItem>
      </GridContainer>
    </div>
  );
}
