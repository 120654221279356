import React, { useEffect, useState } from 'react';
import GroupByBar from './group_by_bar';
import GroupRow from './group_row';
import MaterialTable from 'material-table';
import Error from '../components/error.js';
import { Link } from 'react-router-dom';
import { favorite_customers, favorite_projects } from '../api/user';
import { useTranslation } from 'react-i18next';

export default function Home() {
  const grouppage = 'favorites.'
  const groupprojectspage = 'favorites.projects.'
  const { t, i18n } = useTranslation();
  const [hasError, setErrors] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [columns, setColumns] = useState([]);
  const [projectColumns, setProjectColumns] = useState([]);

  async function fetchData() {
    const [favorite_customer, favorite_project] = await Promise.all([favorite_customers(), favorite_projects()])
    await favorite_customer.json().then(r => (r && r.error) ? setErrors(r.error) : setCustomers(r.data))

    setProjects(await favorite_project.json().then(r => (r && r.error) ? setErrors(r.error) : r.data))
  }

  useEffect(() => {
    fetchData()
    setColumns([
      {
        title: t('customers'),
        field: 'name',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'name') || -1,
        render: row => row.id && <Link to={'/customer/' + (row.customer_id ? 'project/' : '') + row.id}>{row.name}</Link> || row, grouping: true
      },
      {
        title: t('country'),
        field: 'country.name',
        defaultGroupOrder: sessionStorage.getItem(grouppage + 'country.name') || -1,
      },
    ])
    setProjectColumns([
      {
        title: t('project'),
        field: 'name',
        defaultGroupOrder: sessionStorage.getItem(groupprojectspage + 'name') || -1,
        render: row => row.id && <Link to={'/customer/' + (row.customer_id ? 'project/' : '') + row.id}>{row.name}</Link> || row,
      },
      {
        title: t('adres'),
        field: 'address',
        defaultGroupOrder: sessionStorage.getItem(groupprojectspage + 'address') || -1,
      },
      {
        title: t('postal_code'), field: 'postal_code',
        defaultGroupOrder: sessionStorage.getItem(groupprojectspage + 'postal_code') || -1,
      },
      {
        title: t('city'), field: 'city',
        defaultGroupOrder: sessionStorage.getItem(groupprojectspage + 'city') || -1,
      },
    ])
  }, [i18n.lang, t])

  const localization = {
    pagination: {
      labelDisplayedRows: '{from}-{to} of {count}',
      labelRowsSelect: t("labelRowsSelect"),
      labelRowsPerPage: t("labelRowsPerPage"),
      firstAriaLabel: t("firstAriaLabel"),
      firstTooltip: t("firstTooltip"),
      previousAriaLabel: t("previousAriaLabel"),
      previousTooltip: t("previousTooltip"),
      nextAriaLabel: t("nextAriaLabel"),
      nextTooltip: t("nextTooltip"),
      lastAriaLabel: t("lastAriaLabel"),
      lastTooltip: t("lastTooltip"),
    },
    toolbar: {
      nRowsSelected: '{0} row(s) selected',
      searchTooltip: t("search"),
      searchPlaceholder: t("search"),
      exportTitle: t("export"),
    },
    header: {
      actions: t("action")
    },
    grouping: {
      placeholder: t('groupingPlaceholder'),
    },
    body: {
      emptyDataSourceMessage: t("no_records"),
      filterRow: {
        filterTooltip: t("filter")
      },
      editRow: {
        saveTooltip: t("save"),
        cancelTooltip: t("cancel"),
        deleteText: t("delete_text")
      },
      addTooltip: t("add"),
      deleteTooltip: t("delete"),
      editTooltip: t("edit")
    }
  }

  return (<>
    { hasError && <Error hasError={hasError} setErrors={() => setErrors()} />}
    <MaterialTable
      title={`${t('favorites')} ${t('customers')}`}
      onGroupRemoved={(group) => {
        sessionStorage.removeItem(grouppage + group.field)
      }}
      localization={localization}
      columns={columns}
      data={customers}
      options={{
        grouping: true,
        search: false,
        paging: false,
      }}
      components={{
        GroupRow: props => (new GroupRow(props)),
        Groupbar: props => (GroupByBar(props, grouppage))
      }}
    />
    <br />
    <MaterialTable
      title={`${t('favorites')} ${t('projects')}`}
      onGroupRemoved={(group) => {
        sessionStorage.removeItem(groupprojectspage + group.field)
      }}
      localization={localization}
      columns={projectColumns}
      data={projects}
      options={{
        grouping: true,
        search: false,
        paging: false,
      }}
      components={{ Groupbar: props => (GroupByBar(props, groupprojectspage)) }}
    />
  </>);
}
