export function rowStyles(row) {
  if(!row) return
  if(row.sensor && row.sensor.connection_status === 'error') return 'rgba(242, 38, 19, 0.9)'
  if(row.volume_status === 'ok' && row.battery_status === 'ok') return 'rgba(63, 195, 128, 0.9)'
  if(row.volume_status === 'error' || row.battery_status === 'error') return 'rgba(242, 38, 19, 0.9)'
  if(row.volume_status === 'warning' || row.battery_status === 'warning') return 'rgba(248, 148, 6, 0.9)'
  return 'rgba(242, 38, 19, 0.9)'
};

export function levelStyles(row) {
  if(!row) return;
  return levelStatusStyles(row.volume_status)
};

export function levelStatusStyles(volume_status) {
  if(!volume_status) return;
  if(volume_status === 'error') return 'rgba(242, 38, 19, 0.9)'
  if(volume_status  === 'warning') return 'rgba(248, 148, 6, 0.9)'
  return 'rgba(63, 195, 128, 0.9)'
};

export function batteryStyles(row) {
  if(!row) return
  return batteryStatusStyle(row.battery_status)
};

export function batteryStatusStyle(battery_status) {
  if(!battery_status) return
  if(battery_status === 'error') return 'rgba(242, 38, 19, 0.9)'
  if(battery_status === 'warning') return 'rgba(248, 148, 6, 0.9)'
  return 'rgba(63, 195, 128, 0.9)'
};