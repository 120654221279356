import { logout } from "./logout";
import i18n from '../i18n';

export function errorMessage(res, setErrors) {
    let text = i18n.t('connection_error')
    if (res.error) {
      if (!res.error.message && !res.error.code) {
        logout()
      }
      text = i18n.t(res.error.message)
      let v = res.error.validation
      if (v) {
        let k = Object.keys(v)
        for (var i = 0; i < k.length; i++) {
          text += ' ' + i18n.t(v[k[i]][0])
        }
      }
    }
    setErrors(text)
}
