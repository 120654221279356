import { url, headers } from './url';

export async function customer(id) {
  return await fetch(url + 'customer/' + id, {
    method: 'GET',
    headers: headers()
  })
};

export async function customers() {
  return await fetch(url + 'customer', {
    method: 'GET',
    headers: headers()
  });
};

export async function customer_add(data) {
  const res = await fetch(url + 'customer', {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function customer_update(data) {
  const res = await fetch(url + 'customer/' + data.id, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function customer_del(id) {
  const res = await fetch(url + 'customer/' + id, {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json() 
};
