// users, users_add, users_update, users_del
import { url, headers } from './url';

// user
export async function users() {
  return await fetch(url + 'user', {
    method: 'GET',
    headers: headers()
  })
};

export async function users_update(data) {
  const response = await fetch(url + 'user/' + data.id, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return response.json()
};

export async function users_add(data) {
  const res = await fetch(url + 'user', {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};
export async function users_del(id) {
  const res = await fetch(url + 'user/' + id, {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json()
};
