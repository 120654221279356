import { url, headers } from './url';

// container Locations
export async function bin_type() {
  return await fetch(url + 'bin_type', {
    method: 'GET',
    headers: headers()
  });
};

export async function bin_type_add(data) {
  const res = await fetch(url + 'bin_type', {
    method: 'POST',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json()
};

export async function bin_type_update(data) {
  const res = await fetch(url + 'bin_type/' + data.id, {
    method: 'PUT',
    headers: headers(),
    body: JSON.stringify(data)
  });
  return res.json();
};

export async function bin_type_del(id) {
  const res = await fetch(url + 'bin_type/' + id, {
    method: 'DELETE',
    headers: headers()
  });
  return res.ok || res.json()
};
